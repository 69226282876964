import React, { useEffect, useState, useRef, useCallback } from "react";
import { useRiggingContext } from "../../contexts/RiggingContext";
import {
	getAbbreviationForKeypoint,
	getColorForKeypoint,
	Keypoint,
} from "./keypoint";
import { useCharacterPreviewContext } from "@/contexts";
import { KeypointMarker, Position } from "./KeypointMarker";

type KeypointsProps = {
	canvasWidth: number;
	canvasHeight: number;
};

export const Keypoints: React.FC<KeypointsProps> = ({
	canvasHeight,
	canvasWidth,
}) => {
	const centerX = canvasWidth / 2;
	const { riggingKeyLocation, setRiggingKeyLocation } = useRiggingContext();
	const [isSymmetry, setSymmetry] = useState<boolean>(true);
	const { dimensions } = useCharacterPreviewContext();
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const isMobile = dimensions.width < 500;
		const labelHeight = (2 * canvasHeight) / 3;
		const labelSpacing = Math.round(labelHeight / 5);
		const initialPositions: Keypoint = {
			chin: { x: isMobile ? 80 : 100, y: canvasHeight - labelSpacing * 5 - 10 },
			larm: { x: isMobile ? 80 : 100, y: canvasHeight - labelSpacing * 4 - 10 },
			rarm: {
				x: isMobile ? 110 : 150,
				y: canvasHeight - labelSpacing * 4 - 10,
			},
			lelbow: {
				x: isMobile ? 80 : 100,
				y: canvasHeight - labelSpacing * 3 - 10,
			},
			relbow: {
				x: isMobile ? 110 : 150,
				y: canvasHeight - labelSpacing * 3 - 10,
			},
			lknee: {
				x: isMobile ? 80 : 100,
				y: canvasHeight - labelSpacing * 2 - 10,
			},
			rknee: {
				x: isMobile ? 110 : 150,
				y: canvasHeight - labelSpacing * 2 - 10,
			},
			groin: { x: isMobile ? 80 : 100, y: canvasHeight - labelSpacing - 10 },
		};
		setRiggingKeyLocation(initialPositions);
	}, [canvasHeight, dimensions.width, setRiggingKeyLocation]);

	const handleDrag = (key: string, newPosition: Position) => {
		setRiggingKeyLocation((prev: Keypoint) => {
			const newKeypoints: Keypoint = { ...prev };
			newKeypoints[key] = newPosition;

			if (isSymmetry) {
				if (["larm", "lelbow", "lknee"].includes(key)) {
					const rightKey = "r" + key.substring(1);
					const offsetFromCenter = centerX - newPosition.x;
					newKeypoints[rightKey] = {
						x: centerX + offsetFromCenter,
						y: newPosition.y,
					};
				} else if (["rarm", "relbow", "rknee"].includes(key)) {
					const leftKey = "l" + key.substring(1);
					const offsetFromCenter = newPosition.x - centerX;
					newKeypoints[leftKey] = {
						x: centerX - offsetFromCenter,
						y: newPosition.y,
					};
				}
			}

			return newKeypoints;
		});
	};

	return (
		<div
			ref={containerRef}
			style={{
				position: "relative",
				width: `${canvasWidth}px`,
				height: `${canvasHeight}px`,
			}}
		>
			<div className="absolute bottom-7 sm:bottom-11 left-0 h-2/3 flex flex-col justify-evenly pl-4 space-y-3 sm:space-y-5">
				{["Chin", "Wrists", "Elbows", "Knees", "Groin"].map((label) => (
					<div
						key={label}
						className="text-white font-medium text-xs sm:text-medium select-none"
						style={{
							textShadow:
								"0 0 3px rgba(0, 0, 0, 0.8), 0 0 4px rgba(0, 0, 0, 0.6)",
						}}
					>
						{label}
					</div>
				))}
			</div>
			{Object.entries(riggingKeyLocation).map(([key, position]) => (
				<KeypointMarker
					key={key}
					color={getColorForKeypoint(key)}
					position={position}
					onDrag={(newPosition) => handleDrag(key, newPosition)}
					title={getAbbreviationForKeypoint(key)}
					parentRef={containerRef}
				/>
			))}
			{isSymmetry && (
				<svg
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						pointerEvents: "none",
					}}
				>
					<line
						x1={canvasWidth / 2}
						y1="0"
						x2={canvasWidth / 2}
						y2={canvasHeight}
						stroke="rgba(255,255,255,0.75)"
						strokeWidth="2"
						strokeDasharray="5,5"
					/>
				</svg>
			)}
		</div>
	);
};
