"use client";

import { useCharacterCreationContext } from "@/contexts";
import { CharacterCreationStep } from "@/hooks/useCharacterCreation";

export const CharacterPanelContainer: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const { step } = useCharacterCreationContext();
	return (
		<div
			className={`w-full ${
				(step === CharacterCreationStep.processing ||
					step === CharacterCreationStep.initial ||
					step === CharacterCreationStep.upload) &&
				"sm:w-auto"
			} sm:min-w-[500px]`}
		>
			{children}
		</div>
	);
};
