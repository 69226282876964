import { useAuth } from "@/components/Auth";
import { useCharacterCreationContext } from "../../contexts/CharacterCreationContext";
import { AuthenticatedStepCard, StepCard, StepCardIcon } from "@/components/";
import { compressImage } from "@/utils";
import { BsUniversalAccess } from "react-icons/bs";

export const ImageImporter: React.FC = () => {
	const { previewImageUpload } = useCharacterCreationContext();
	const title = "Option 1: Generate your character — 2 credits";
	const description =
		"Please upload a full-body image that shows the entire character from head to toe";
	const { userData } = useAuth();
	return (
		<AuthenticatedStepCard
			title={title}
			description={description}
			disabledNoCredits={userData?.credits! < 2}
		authMessage="Please sign in to create custom characters"
			isAvailableForFree={true}
			onUpload={async (file: File) => {
				try {
					const compressedFile = await compressImage(file);
					previewImageUpload(compressedFile);
				} catch (error) {
					console.error("Error compressing image:", error);
					// Fallback to original file if compression fails
					previewImageUpload(file);
				}
			}}
			accept=".jpg, .jpeg, .png"
		>
			<StepCardIcon icon={<BsUniversalAccess size={27} />} />
			<div className="flex flex-col items-center mt-3">
				<span className="text-xs text-muted">
					Only jpg, jpeg, and png files are allowed.
				</span>
			</div>
		</AuthenticatedStepCard>
	);
};
