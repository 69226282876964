import React, { useEffect, useState, useRef, useCallback } from "react";
import { Tooltip } from "@nextui-org/react";
import { useCharacterPreviewContext } from "@/contexts";
import styles from "./keypoint.module.css";

export type Position = { x: number; y: number };

export const KeypointMarker = ({
	color,
	position,
	onDrag,
	title,
	parentRef,
}: {
	color: string;
	position: Position;
	onDrag: (newPosition: Position) => void;
	title: string;
	parentRef: React.RefObject<HTMLDivElement>;
}) => {
	const [isDragging, setIsDragging] = useState(false);
	const markerRef = useRef<HTMLDivElement>(null);
	const startPosRef = useRef<Position>({ x: 0, y: 0 });
	const { dimensions } = useCharacterPreviewContext();

	const preventDefault = useCallback((e: Event) => {
		e.preventDefault();
	}, []);

	// Add user-select CSS class when dragging starts
	useEffect(() => {
		if (isDragging) {
			document.body.style.userSelect = "none";
			document.body.style.overflow = "hidden";

			// Prevent touch events from scrolling on mobile
			document.addEventListener("touchmove", preventDefault, {
				passive: false,
			});
		} else {
			document.body.style.userSelect = "";
			document.body.style.overflow = "";
			document.removeEventListener("touchmove", preventDefault);
		}

		return () => {
			document.body.style.userSelect = "";
			document.body.style.overflow = "";
			document.removeEventListener("touchmove", preventDefault);
		};
	}, [isDragging, preventDefault]);

	const handleStart = useCallback(
		(clientX: number, clientY: number) => {
			if (parentRef.current && markerRef.current) {
				const parentRect = parentRef.current.getBoundingClientRect();
				startPosRef.current = {
					x: clientX - parentRect.left - position.x,
					y: clientY - parentRect.top - position.y,
				};
				setIsDragging(true);
			}
		},
		[parentRef, position]
	);

	const handleMove = useCallback(
		(clientX: number, clientY: number) => {
			const isDesktop = dimensions.width >= 768;
			const MARKER_SIZE = isDesktop ? 36 : 24;
			const MARKER_BORDER = isDesktop ? 8 : 6;
			if (!isDragging || !parentRef.current) return;

			const parentRect = parentRef.current.getBoundingClientRect();

			let newX = clientX - parentRect.left - startPosRef.current.x;
			let newY = clientY - parentRect.top - startPosRef.current.y;

			// Boundary checks
			newX = Math.max(
				MARKER_SIZE / 2 + MARKER_BORDER,
				Math.min(newX, parentRect.width - MARKER_SIZE / 2 - MARKER_BORDER)
			);
			newY = Math.max(
				MARKER_SIZE / 2 + MARKER_BORDER,
				Math.min(newY, parentRect.height - MARKER_SIZE / 2 - MARKER_BORDER)
			);

			onDrag({ x: newX, y: newY });
		},
		[dimensions.width, isDragging, onDrag, parentRef]
	);

	const handleEnd = useCallback(() => {
		setIsDragging(false);
	}, []);

	const handleMouseDown = useCallback(
		(e: React.MouseEvent) => {
			e.preventDefault(); // Prevent text selection on drag start
			handleStart(e.clientX, e.clientY);
		},
		[handleStart]
	);

	const handleTouchStart = useCallback(
		(e: React.TouchEvent) => {
			e.preventDefault(); // Prevent scrolling on touch devices
			const touch = e.touches[0];
			handleStart(touch.clientX, touch.clientY);
		},
		[handleStart]
	);

	const handleMouseMove = useCallback(
		(e: MouseEvent) => {
			handleMove(e.clientX, e.clientY);
		},
		[handleMove]
	);

	const handleTouchMove = useCallback(
		(e: TouchEvent) => {
			const touch = e.touches[0];
			handleMove(touch.clientX, touch.clientY);
		},
		[handleMove]
	);

	useEffect(() => {
		if (isDragging) {
			window.addEventListener("mousemove", handleMouseMove);
			window.addEventListener("mouseup", handleEnd);
			window.addEventListener("touchmove", handleTouchMove);
			window.addEventListener("touchend", handleEnd);
		}
		return () => {
			window.removeEventListener("mousemove", handleMouseMove);
			window.removeEventListener("mouseup", handleEnd);
			window.removeEventListener("touchmove", handleTouchMove);
			window.removeEventListener("touchend", handleEnd);
		};
	}, [isDragging, handleMouseMove, handleTouchMove, handleEnd]);

	return (
		<div
			ref={markerRef}
			className={`${styles.keypoint} ${isDragging ? "select-none" : ""}`}
			style={{
				borderColor: color,
				left: `${position.x}px`,
				top: `${position.y}px`,
				cursor: isDragging ? "grabbing" : "grab",
				touchAction: "none", // Prevents default touch behaviors
			}}
			onMouseDown={handleMouseDown}
			onTouchStart={handleTouchStart}
		>
			<Tooltip
				content={<div className="font-semibold">{title}</div>}
				placement="right"
				showArrow
				offset={20}
			>
				<div className="w-full h-full"></div>
			</Tooltip>
		</div>
	);
};

export default KeypointMarker;
