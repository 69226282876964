// hooks/useAuthErrorInterceptor.ts
"use client";

import { useSearchParams } from "next/navigation";
import { useAuth } from "./AuthContext";
import { useEffect, useCallback } from "react";

export function useAuthErrorInterceptor() {
	const searchParams = useSearchParams();
	const { openAuthModal } = useAuth();

	const handleAuthError = useCallback(() => {
		const authError = searchParams.get("authError");
		const authErrorDescription = searchParams.get("authErrorDescription") || "";

		if (authError) {
			openAuthModal(undefined, authError, authErrorDescription);
		}
	}, [searchParams, openAuthModal]);

	useEffect(() => {
		handleAuthError();
	}, [handleAuthError]);

	return null;
}
