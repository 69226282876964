"use client";

import React, { useState } from "react";
import { useAnimationCreationContext } from "@/contexts";
import { AuthenticatedStepCard, StepCard, StepCardIcon } from "@/components";
import { IoVideocamOutline } from "react-icons/io5";
import { useAuth } from "@/components/Auth";
import { useFFmpeg } from "../record/hooks/useFFmpeg";
import { makeToastError, trimVideo } from "@/utils";
import { Spinner } from "@nextui-org/react";

interface VideoImporterProps {
	disabled: boolean;
}

export const VideoImporter: React.FC<VideoImporterProps> = ({ disabled }) => {
	const { previewVideoUpload } = useAnimationCreationContext();
	const ffmpeg = useFFmpeg();
	const [isProcessing, setIsProcessing] = useState(false);
	const title = "Import a video — 1 credit";
	const description =
		"Upload a 20 seconds motion video to animate your character";
	const handleUpload = async (file: File) => {
		if (!ffmpeg) {
			console.error("FFmpeg not loaded");
			return;
		}

		try {
			setIsProcessing(true);
			const trimmedVideo = await trimVideo(file, ffmpeg);
			previewVideoUpload(trimmedVideo);
		} catch (error) {
			console.error("Error processing video:", error);
			makeToastError({ message: "Error processing video. Trimming failed." });
		} finally {
			setIsProcessing(false);
		}
	};
	const { userData } = useAuth();

	return (
		<AuthenticatedStepCard
			title={title}
			description={description}
			disabled={disabled || isProcessing}
			disabledNoCredits={userData?.credits === 0}
			authMessage="Sign in to create custom animations"
			accept=".mp4, .mov, .avi"
			onUpload={handleUpload}
		>
			<StepCardIcon icon={<IoVideocamOutline size={27} />} />
			{isProcessing && <Spinner />}
			<span className="text-xs text-muted mt-3">
				{isProcessing
					? "Processing video..."
					: "Only mp4, mov, and avi files are allowed. Video longer than 20 seconds will be trimmed."}
			</span>
		</AuthenticatedStepCard>
	);
};
