import React, {
	useState,
	useCallback,
	Suspense,
	useRef,
} from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { ACESFilmicToneMapping, SRGBColorSpace } from "three";
import { MeshComponent } from "./MeshComponent";
import { AnimationButton } from "./AnimationButton";
import {
	IoPlayOutline,
	IoVolumeHighOutline,
	IoVolumeMuteOutline,
} from "react-icons/io5";
import {
	useCharacter,
	useCharacterCreationContext,
	useCharacterPreviewContext,
} from "@/contexts";
import { useAudio } from "../steps/motion-transfer/useAudio";
import { CharacterCreationStep } from "@/hooks/useCharacterCreation";
import { MdBolt } from "react-icons/md";
import { Button, CircularProgress } from "@nextui-org/react";
import { EventName, getMixPanelClient } from "@/app/mixpanel";
import * as Sentry from "@sentry/react";

type MeshCanvasProps = {
	fileType: "obj" | "fbx" | "gltf";
	showAnimationControls?: boolean;
	showAudioControls?: boolean;
};

const Lighting: React.FC = () => (
	<>
		<ambientLight intensity={0.5} />
		<directionalLight position={[0, 2, 5]} intensity={1.5} castShadow />
		<directionalLight position={[0, 5, 0]} intensity={0.8} castShadow={false} />
		<directionalLight
			position={[0, 2, -5]}
			intensity={0.8}
			castShadow={false}
		/>
	</>
);

const AudioControl: React.FC<{ isMuted: boolean; onToggle: () => void }> = ({
	isMuted,
	onToggle,
}) => {
	const mp = getMixPanelClient();
	return (
		<Button
			isIconOnly
			variant="bordered"
			onPress={() => {
				mp.track(EventName.ToggleAudio, {
					state: isMuted ? "unmute" : "mute",
				});
				onToggle();
			}}
			className="absolute top-4 right-4 rounded-full bg-gray-900/70"
			aria-label={isMuted ? "Unmute" : "Mute"}
		>
			{isMuted ? (
				<IoVolumeMuteOutline className="w-5 h-5 sm:w-7 sm:h-6" />
			) : (
				<IoVolumeHighOutline className="w-5 h-5 sm:w-7 sm:h-6" />
			)}
		</Button>
	);
};

export const MeshCanvas: React.FC<MeshCanvasProps> = ({
	fileType,
	showAnimationControls = false,
	showAudioControls = false,
}) => {
	const { step } = useCharacterCreationContext();
	const { threeElements } = useCharacterPreviewContext();
	let animBtnText = "Jump!";
	let animBtnIcon = <MdBolt className="w-5 h-5 sm:w-6 sm:h-6" />;
	let animBtnDisabledText = "Jumping...";
	if (step === CharacterCreationStep.motion) {
		animBtnText = "Play animation";
		animBtnIcon = <IoPlayOutline className="w-5 h-5 sm:w-6 sm:h-6" />;
		animBtnDisabledText = "Animating...";
	}
	const [isAnimating, setIsAnimating] = useState(false);
	const { character } = useCharacter();
	const [isMuted, setIsMuted] = useState(false);
	const { play, stop, mute, unmute } = useAudio(character?.animatedAudio);
	const meshComponentRef = useRef<{ resetCamera: () => void }>(null);
	const startAnimation = useCallback(() => {
		if (meshComponentRef.current) {
			meshComponentRef.current.resetCamera();
		}
		if (!isMuted) {
			play();
		}
		setIsAnimating(true);
	}, [play, isMuted]);

	const onAnimationComplete = useCallback(() => {
		stop();
		setIsAnimating(false);
		if (meshComponentRef.current) {
			meshComponentRef.current.resetCamera();
		}
	}, [stop]);

	const toggleMute = useCallback(() => {
		setIsMuted((prev) => {
			const newMutedState = !prev;
			if (newMutedState) {
				mute();
			} else {
				unmute();
			}
			return newMutedState;
		});
	}, [mute, unmute]);
	const [loadingProgress, setLoadingProgress] = useState(0);

	return (
		<Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
			<Canvas
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
				}}
				orthographic={step !== CharacterCreationStep.motion}
				camera={
					step !== CharacterCreationStep.motion
						? {
								position: [0, 0, 2.099573721083259],
								near: 0.1,
								far: 1e4,
								left: -0.3,
								right: 0.3,
								top: 0.3,
								bottom: -0.3,
						  }
						: {
								position: [0, 0, 1.529573721083259],
								fov: 50,
								near: 0.1,
								far: 1e4,
						  }
				}
				onCreated={({ camera, gl, scene }) => {
					gl.toneMapping = ACESFilmicToneMapping;
					gl.toneMappingExposure = 0.8;
					gl.outputColorSpace = SRGBColorSpace;
				}}
			>
				<Lighting />
				<Suspense fallback={null}>
					<MeshComponent
						ref={meshComponentRef}
						fileType={fileType}
						isAnimating={isAnimating}
						onAnimationComplete={onAnimationComplete}
						onLoadingProgress={setLoadingProgress}
						frustumSizeConstant={
							step === CharacterCreationStep.previewRigging ? 1.425 : 1.025
						}
					/>
				</Suspense>
				<OrbitControls
					enabled={step !== CharacterCreationStep.rigging}
					target={[0, threeElements.camera.position.y, 0]}
					maxZoom={3}
					minZoom={0.8}
					maxDistance={2}
					minDistance={0.3}
				/>
			</Canvas>
			{loadingProgress < 100 && (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<CircularProgress
						aria-label="Loading character"
						size="lg"
						value={loadingProgress}
						color="success"
						showValueLabel={true}
					/>
				</div>
			)}
			{showAudioControls && character?.animatedAudio && (
				<AudioControl isMuted={isMuted} onToggle={toggleMute} />
			)}
			{showAnimationControls && (fileType === "fbx" || fileType === "gltf") && (
				<AnimationButton
					onAnimationStart={startAnimation}
					disabled={isAnimating}
					disabledText={animBtnDisabledText}
					text={animBtnText}
					icon={animBtnIcon}
				/>
			)}
		</Sentry.ErrorBoundary>
	);
};
