import { DashedGradientCircle } from "./DashedGradientCircle";
import { StepCardIconProps } from "./props";
import styles from "./StepCardIcon.module.css";

export const StepCardIcon: React.FC<StepCardIconProps> = ({ icon }) => {
	return (
		<div className="mx-auto">
			<DashedGradientCircle>{icon}</DashedGradientCircle>
		</div>
	);
};
