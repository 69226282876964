import { EventName, getMixPanelClient } from "@/app/mixpanel";
import { useCharacter } from "@/contexts";
import { CharacterCreationStep } from "@/hooks/useCharacterCreation";
import { generateMTLContent } from "@/utils";
import { Tooltip, Button } from "@nextui-org/react";
import axios from "axios";
import saveAs from "file-saver";
import JSZip from "jszip";
import { useCallback, useMemo, useState } from "react";
import { IoDownloadOutline } from "react-icons/io5";
import { DownloadConfig, DownloadType } from "./types";
import { useDownloadCharacter } from "./useDownloadCharacter";
import { useAuth } from "@/components/Auth";

interface DownloadButtonProps {
	step: CharacterCreationStep;
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({ step }) => {
	const { isDownloading, handleDownload } = useDownloadCharacter();

	const mp = getMixPanelClient();
	const { userData } = useAuth();

	const downloadConfig = useMemo<
		Partial<Record<CharacterCreationStep, DownloadConfig>>
	>(
		() => ({
			[CharacterCreationStep.previewMesh]: {
				type: DownloadType.Obj,
				tooltip: "Download the OBJ file — 3 credits",
			},
			[CharacterCreationStep.previewRigging]: {
				type: DownloadType.Rigged,
				tooltip: "Download the rigged character in FBX — 3 credits",
			},
			[CharacterCreationStep.motion]: {
				type: DownloadType.Animation,
				tooltip: "Download the animation in FBX  — 3 credits",
			},
		}),
		[]
	);

	const config = downloadConfig[step];

	if (!config) return null;
	console.log(userData);
	return (
		<Tooltip
			content={userData?.credits! < 3 ? "No credits left" : config.tooltip}
		>
			<div>
				<Button
					isLoading={isDownloading}
					isIconOnly
					isDisabled={(userData?.credits ?? 0) < 3}
					onPress={async () => {
						mp.track(EventName.Download, {
							step: step,
							downloadType: config.type,
						});
						await handleDownload(config.type);
					}}
					className="w-8 h-8 min-w-8 min-h-8 sm:w-10 sm:h-10 sm:min-w-10 sm:min-h-10 md:w-10 md:h-10 md:min-w-10 md:min-h-10"
				>
					<IoDownloadOutline className="w-5 h-5 md:w-6 md:h-6 lg:w-6 lg:h-6" />
				</Button>
			</div>
		</Tooltip>
	);
};
