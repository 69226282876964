"use client";
import React, { useCallback, useRef } from "react";
import { Card, CardBody } from "@nextui-org/card";
import { Button, Chip, Input, Tooltip } from "@nextui-org/react";
import { IoMailOutline } from "react-icons/io5";
import { useAuth } from "../Auth";
import styles from "./StepCard.module.css";
import { withAuth } from "../Auth/withAuth";
import { StepCardProps } from "./props";
import { EventName, getMixPanelClient } from "@/app/mixpanel";
import { FaDiscord } from "react-icons/fa";

const NoCreditsDescription = () => (
	<div className="p-1">
		<div className="text-center max-w-56">
			<h6 className="font-medium text-lg">You&apos;re out of credits!</h6>
			<div>
				Please reach out to us to get more credits. In the meantime, feel free
				to explore the already generated characters and motions.
			</div>
			<Button
				className="mt-2"
				color="primary"
				size="sm"
				startContent={<IoMailOutline size={18} />}
				onPress={() => {
					window.open("mailto:kevin@ooma.live");
				}}
			>
				Contact Support
			</Button>
		</div>
	</div>
);

const MaintenanceDescription = () => {
	const mp = getMixPanelClient();
	return (
		<div className="p-1">
			<div className="text-center max-w-56">
				<h6 className="font-medium text-lg">
					We are currently fixing some bugs!
				</h6>
				<div>
					Please check back later to generate custom characters. In the
					meantime, feel free to explore the already generated characters and
					motions.
				</div>
				<Button
					as="a"
					size="sm"
					href="https://discord.gg/xYY3x4tZVF"
					target="_blank"
					rel="noopener noreferrer"
					className="font-medium mt-2"
					startContent={
						<FaDiscord
							size={24}
							className="text-white hover:text-purple-400 transition-colors"
						/>
					}
					onClick={() => {
						mp.track(EventName.DiscordLinkClick);
					}}
				>
					Join our Discord for updates!
				</Button>
			</div>
		</div>
	);
};
export const StepCard: React.FC<StepCardProps> = ({
	children,
	disabledNoCredits = false,
	title,
	description,
	onUpload,
	disabled,
	accept,
	isAvailableForFree,
	disabledMaintenance,
}) => {
	const fileInputRef = useRef<HTMLInputElement>(null);
	const { isAuthenticated } = useAuth();
	// FEATURE DISABLED: Free generations
	// const { freeGenerationCount } = useUserData();

	const mp = getMixPanelClient();

	const handleFileChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const file = event.target.files?.[0];
			if (file && onUpload) {
				onUpload(file);
			}
		},
		[onUpload]
	);

	// FEATURE DISABLED: Free generations
	// const isFree = isAvailableForFree && freeGenerationCount > 0;

	const triggerFileUpload = () => {
		if (isAuthenticated) {
			mp.track(EventName.UploadFile, {
				step: title,
			});
			fileInputRef.current?.click();
		}
	};

	return (
		<Tooltip
			isDisabled={(!disabledNoCredits && !disabledMaintenance) || disabled}
			content={
				disabledNoCredits ? (
					<NoCreditsDescription />
				) : (
					disabledMaintenance && <MaintenanceDescription />
				)
			}
			showArrow
		>
			<Card
				isPressable={!disabledNoCredits && !disabled && !disabledMaintenance}
				className={`w-full ${styles.background} p-2 relative`}
				onPress={triggerFileUpload}
				isDisabled={disabledNoCredits || disabledMaintenance}
				disableAnimation={disabledNoCredits || disabledMaintenance}
				disableRipple={disabledNoCredits || disabledMaintenance}
				shadow="none"
			>
				<CardBody className="text-center space-y-4">
					<h4 className="font-semibold text-l">{title}</h4>
					<p className="text-paragraph text-sm sm:text-base">{description}</p>
					{children}
					<Input
						type="file"
						accept={accept}
						onChange={handleFileChange}
						hidden
						className="hidden"
						ref={fileInputRef}
					/>
				</CardBody>
			</Card>
		</Tooltip>
	);
};

export const AuthenticatedStepCard = withAuth(StepCard);
