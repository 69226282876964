import React from "react";
import { Button } from "@nextui-org/react";
import { IoCloseOutline, IoFlashOutline } from "react-icons/io5";
import { CharacterCreationView } from "../viewer/CharacterCreationView";
import { useCharacterCreationContext } from "../../../contexts/CharacterCreationContext";
import { EventName, getMixPanelClient } from "@/app/mixpanel";
import { useCharacterRedoContext } from "@/contexts";

export const CharacterFinalView: React.FC = () => {
	const { cancelCreation } = useCharacterCreationContext();
	const {clearCharacters} = useCharacterRedoContext();
	const mp = getMixPanelClient();
	return (
		<div className="text-center space-y-2">
			<h3 className="text-medium sm:text-xl font-semibold">
				Your 3D character is ready for animation!
			</h3>

			<CharacterCreationView showAnimation={true} />

			<div className="space-x-3">
				<Button
					variant="light"
					startContent={<IoCloseOutline size={15} />}
					onPress={() => {
						mp.track(EventName.CancelCreation, {
							step: "Character CharacterFinalView",
						});
						clearCharacters();
						cancelCreation();
					}}
					size="lg"
				>
					Cancel
				</Button>
				{/* <Button
					startContent={<IoFlashOutline size={15} />}
					size="lg"
					onPress={startAnimationCreation}
				>
					Start Animation
				</Button> */}
			</div>
		</div>
	);
};
