"use client";
import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader } from "@nextui-org/card";
import { ScrollShadow } from "@nextui-org/react";
import styles from "./TemplateSelector.module.css";
import { makeToastError } from "@/utils";
import { useCharacter } from "@/contexts/CharacterContext";
import { useCharacterCreationContext } from "@/contexts/CharacterCreationContext";
import { CharacterCreationStep } from "@/hooks/useCharacterCreation";
import Image from "next/image";
import { EventName, getMixPanelClient } from "../mixpanel";

export interface TemplateCharacter {
	id: string;
	name: string;
	preview_image: string | null;
	preview_video: string | null;
}

const TemplatePreview: React.FC<{
	template: TemplateCharacter;
	disableVideoLoop: boolean;
}> = ({ template, disableVideoLoop }) => {
	if (template.preview_video) {
		return (
			<video
				src={template.preview_video}
				className="absolute inset-0 w-full h-full object-cover rounded-[20px]"
				autoPlay
				loop={!disableVideoLoop}
				muted
				playsInline
			/>
		);
	}

	if (template.preview_image) {
		return (
			<Image
				src={template.preview_image}
				alt={template.name}
				width={400}
				height={300}
				className="absolute inset-0 w-full h-full object-cover rounded-[20px]"
			/>
		);
	}

	return (
		<div className="absolute inset-0 w-full h-full bg-gray-600 flex items-center justify-center rounded-[20px]">
			<span className="text-gray-300 text-lg">No preview</span>
		</div>
	);
};

interface TemplateSelectorProps {
	disableVideoLoop?: boolean;
}

export const TemplateSelector: React.FC<TemplateSelectorProps> = ({
	disableVideoLoop = false,
}) => {
	const [templates, setTemplates] = useState<TemplateCharacter[]>([]);
	const [error, setError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(true);
	const { setCharacter } = useCharacter();
	const { setStep } = useCharacterCreationContext();

	const mp = getMixPanelClient();

	useEffect(() => {
		fetchTemplates();
	}, []);

	const fetchTemplates = async () => {
		setIsLoading(true);
		setError(null);
		try {
			const response = await fetch("/api/v1/template");
			if (!response.ok) {
				throw new Error(
					`Failed to fetch template characters: ${response.statusText}`
				);
			}
			const data = await response.json();
			setTemplates(data);
		} catch (error: unknown) {
			console.error("Error fetching template characters:", error);
			makeToastError({
				message: `Error fetching template characters: ${error}`,
			});
			setError(
				error instanceof Error ? error.message : "An unknown error occurred"
			);
		} finally {
			setIsLoading(false);
		}
	};

	const handleTemplateSelect = async (template: TemplateCharacter) => {
		try {
			const formData = new FormData();
			formData.append("template_id", template.id);

			const response = await fetch("/api/v1/template", {
				method: "POST",
				body: formData,
			});

			if (!response.ok) {
				const errorText = await response.text();
				throw new Error(
					`Failed to select template character: ${response.statusText}. Error: ${errorText}`
				);
			}

			const data = await response.json();

			setCharacter({
				id: data.character_uuid,
				object: data.final_obj,
				objectTexture: data.final_texture,
				riggedObject: data.rigged_character,
				jumpingCharacter: data.jumping_character,
				isReadyForAnimation: true,
			});

			setStep(CharacterCreationStep.previewRigging);
		} catch (error: unknown) {
			console.error("Error selecting template character:", error);
			if (error instanceof Error) {
				makeToastError({
					message: `Error selecting template character: ${error.message}`,
				});
			} else {
				makeToastError({
					message:
						"An unknown error occurred while selecting template character",
				});
			}
		}
	};

	if (error) {
		return (
			<div className="text-red-500">
				<p>Error: {error}</p>
				<p>
					Please try again later or contact support if the problem persists.
				</p>
			</div>
		);
	}

	return (
		<Card
			className={`w-full h-[20rem] rounded-xl ${styles.background} p-2 relative`}
			shadow="none"
		>
			<CardHeader className="flex justify-center items-center">
				<h4 className="text-l font-medium text-center text-white/80">
					Option 2: Select an already generated character
				</h4>
			</CardHeader>
			<CardBody className="p-0">
				<ScrollShadow
					size={20}
					className="h-full"
					style={{
						scrollbarWidth: "thin",
						scrollbarColor: "rgba(155, 155, 155, 0.5) transparent",
					}}
				>
					{isLoading ? (
						<div className="grid grid-cols-2 gap-4 p-2">
							{[...Array(4)].map((_, index) => (
								<div
									key={index}
									className="w-full h-40 bg-gray-600 rounded-[20px] animate-pulse"
								></div>
							))}
						</div>
					) : templates.length === 0 ? (
						<p className="text-white text-xl text-center p-4">
							No templates available
						</p>
					) : (
						<div className="grid grid-cols-2 gap-4 p-4">
							{templates.slice(0, 4).map((template) => (
								<div
									key={template.id}
									className="relative w-full h-40 cursor-pointer group transition-transform duration-200 ease-in-out transform hover:scale-105 active:scale-95 rounded-[20px] overflow-hidden"
									onClick={() => {
										mp.track(EventName.SelectTemplateCharacter, {
											template_id: template.id,
										});
										handleTemplateSelect(template);
									}}
								>
									<div className="hidden absolute inset-0 sm:flex items-center justify-center opacity-0 group-hover:opacity-70 bg-black text-white text-xl font-semibold z-10 rounded-[20px] transition-opacity duration-300">
										{template.name}
									</div>
									<div className="sm:hidden absolute inset-0 flex items-end justify-center text-white text-lg font-semibold z-10 rounded-[20px] mb-5">
										{template.name}
									</div>
									<TemplatePreview
										template={template}
										disableVideoLoop={disableVideoLoop}
									/>
								</div>
							))}
						</div>
					)}
				</ScrollShadow>
			</CardBody>
		</Card>
	);
};
