"use client";

import { useState, useEffect } from "react";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { toBlobURL } from "@ffmpeg/util";

export const useFFmpeg = () => {
	const [ffmpeg, setFFmpeg] = useState<FFmpeg | null>(null);

	useEffect(() => {
		const loadFFmpeg = async () => {
			const ffmpegInstance = new FFmpeg();
			const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.2/dist/esm";

			try {
				await ffmpegInstance.load({
					coreURL: await toBlobURL(
						`${baseURL}/ffmpeg-core.js`,
						"text/javascript"
					),
					wasmURL: await toBlobURL(
						`${baseURL}/ffmpeg-core.wasm`,
						"application/wasm"
					),
				});
				setFFmpeg(ffmpegInstance);
			} catch (error) {
				console.error("Failed to load FFmpeg:", error);
			}
		};

		// Ensure this code only runs on the client-side
		if (typeof window !== "undefined") {
			loadFFmpeg();
		}
	}, []);

	return ffmpeg;
};
