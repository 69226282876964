import React, { useRef, useEffect, useState } from "react";
import {
	Button,
	Card,
	Input,
	Modal,
	ModalBody,
	ModalContent,
	ModalHeader,
} from "@nextui-org/react";
import styles from "@/components/StepCard/StepCard.module.css";
import { MeshCanvas } from "./MeshCanvas";
import { useCharacterCreationContext } from "../../../contexts/CharacterCreationContext";
import { CharacterCreationStep } from "../../../hooks/useCharacterCreation";
import { useCharacter, useCharacterPreviewContext } from "@/contexts";
import { DownloadButton } from "../steps/mesh-preview/Download/DownloadButton";
import { IoClose } from "react-icons/io5";
import Image from "next/image";
import { ShareButton } from "../steps/mesh-preview/Share/ShareButton";
import { useAuth } from "@/components/Auth";

const ImagePreview: React.FC<{ url: string; onClick: () => void }> = ({
	url,
	onClick,
}) => (
	<div
		className="absolute top-2 left-2 w-12 h-12 sm:w-16 sm:h-16 border border-white rounded-md overflow-hidden cursor-pointer shadow-md"
		onClick={onClick}
		style={{
			backgroundImage: `url(${url})`,
			backgroundPosition: "center",
			backgroundSize: "cover",
		}}
	/>
);

const ImageModal: React.FC<{
	url: string;
	onClose: () => void;
	isOpen: boolean;
}> = ({ url, onClose, isOpen }) => (
	<Modal
		isOpen={isOpen}
		onOpenChange={onClose}
		placement="center"
		backdrop="blur"
		closeButton={
			<Button isIconOnly variant="light">
				<IoClose size={24} />
			</Button>
		}
	>
		<ModalContent className="p-4">
			<ModalHeader className="flex flex-col gap-1">
				<span className="text-3xl text-center">Your prompt</span>
			</ModalHeader>
			<ModalBody>
				<Image src={url} alt="Full Preview" width={400} height={400} />
			</ModalBody>
		</ModalContent>
	</Modal>
);

export const CharacterCreationView: React.FC<{
	children?: React.ReactNode;
	showAnimation?: boolean;
}> = ({ children, showAnimation = false }) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const { setDimensions } = useCharacterPreviewContext();
	const { step } = useCharacterCreationContext();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { character } = useCharacter();
	const { isAuthenticated } = useAuth();

	useEffect(() => {
		if (!containerRef.current) return;
		const resizeObserver = new ResizeObserver((entries) => {
			for (let entry of entries) {
				setDimensions(entry.contentRect);
			}
		});
		resizeObserver.observe(containerRef.current);
		return () => resizeObserver.disconnect();
	}, [setDimensions]);

	const fileType: "obj" | "fbx" | "gltf" =
		step === CharacterCreationStep.previewRigging ||
		step === CharacterCreationStep.motion
			? "fbx"
			: "obj";

	const showAudioControls = !!character?.animatedAudio;

	const isPreviewStep = [
		CharacterCreationStep.previewMesh,
		CharacterCreationStep.previewRigging,
		CharacterCreationStep.motion,
	].includes(step);

	return (
		<Card className={`${styles.background} w-full h-full`} shadow="none">
			<div
				ref={containerRef}
				className="relative w-full h-full min-h-[18rem] sm:min-h-[500px] overflow-hidden"
			>
				<MeshCanvas
					fileType={fileType}
					showAnimationControls={showAnimation}
					showAudioControls={showAudioControls}
				/>
				{children}
				{character?.image && step !== CharacterCreationStep.initial && (
					<ImagePreview
						url={character.image}
						onClick={() => setIsModalOpen(true)}
					/>
				)}
				{isPreviewStep && isAuthenticated && (
					<div className="absolute bottom-4 right-4 flex flex-col gap-4">
						<DownloadButton step={step} />
						<ShareButton />
					</div>
				)}
			</div>
			{isModalOpen && character?.image && (
				<ImageModal
					url={character.image}
					onClose={() => setIsModalOpen(false)}
					isOpen={isModalOpen}
				/>
			)}
		</Card>
	);
};
