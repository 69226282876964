import { Card, CardBody, CardHeader, Tooltip } from "@nextui-org/react";
import { PanelCardProps } from "@/components";
import { IoHelpCircleOutline } from "react-icons/io5";
import styles from "./StudioCard.module.css";
import Image from "next/image";
import { EventName, getMixPanelClient } from "@/app/mixpanel";
import { useState } from "react";
import { StudioTooltip } from "./StudioTooltip";

export type StudioCardProps = PanelCardProps & {
	isStepActive?: boolean;
	displayBorder?: boolean;
	tooltipImage?: string;
	tooltipLink?: string;
	animatedCharacter?: string;
	disableWhenAnimatedCharacterIsNull?: boolean;
};

export const StudioCard: React.FC<StudioCardProps> = ({
	title,
	children,
	icon,
	helpMessage,
	isStepActive = true,
	displayBorder = false,
	tooltipImage,
	tooltipLink,
}) => {
	const mp = getMixPanelClient();

	const TooltipContent = () => (
		<div className="flex flex-col gap-2 items-center max-w-[200px]">
			<p className="text-sm text-center">{helpMessage}</p>
			{tooltipImage && (
				<Image
					src={tooltipImage}
					alt="Example image"
					className="rounded-md max-w-[100px]"
					width={100}
					height={100}
				/>
			)}
			{tooltipLink && (
				<a
					href={tooltipLink}
					className="text-fuchsia-400 hover:underline text-sm"
					target="_blank"
					rel="noopener noreferrer"
					onClick={() => {
						mp.track(EventName.HelpTooltipLinkClick, {
							studioCardTitle: title,
						});
					}}
				>
					Read the documentation
				</a>
			)}
		</div>
	);

	return (
		<Card
			className={`in-line-block p-4 ${displayBorder ? styles["rainbow"] : ""}`}
			isDisabled={!isStepActive}
		>
			<CardHeader className="flex gap-5">
				{icon}
				<h2 className="text-xl sm:text-2xl font-medium text-center text-white/80 space-y-2 ">
					{title}
				</h2>
				<div className="ml-auto">
					<StudioTooltip content={<TooltipContent />} title={title} />
					{/* <Tooltip
						content={<TooltipContent />}
						onOpenChange={(isOpen: boolean) => {
							if (isOpen) {
								mp.track(EventName.HelpTooltip, {
									studioCardTitle: title,
								});
							}
						}}
					>
						<span style={{ cursor: "pointer" }}>
							<IoHelpCircleOutline size={25} />
						</span>
					</Tooltip> */}
				</div>
			</CardHeader>
			<CardBody className="mt-2">{children}</CardBody>
		</Card>
	);
};
