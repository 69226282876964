"use client";
import React, { useState, useEffect, useCallback } from "react";
import { useCharacter, useCharacterCreationContext } from "@/contexts";
import { Card, CardBody } from "@nextui-org/card";
import {
	CircularProgress,
	Spinner,
	Chip,
	Tooltip,
	ScrollShadow,
} from "@nextui-org/react";
import {
	Modal,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	useDisclosure,
} from "@nextui-org/react";
import { MdOutlineVolumeUp } from "react-icons/md";
import { CharacterCreationStep } from "../character";
import { EventName, getMixPanelClient } from "../mixpanel";
import { makeToastError } from "@/utils";
import styles from "@/components/StepCard/StepCard.module.css";
import { IoClose } from "react-icons/io5";
import { useAuth } from "@/components/Auth";
interface AnimationTemplate {
	id: string;
	name: string;
	category: string;
	preview_image: string | null;
	has_audio: boolean | null;
	new: boolean;
	description?: string;
}

interface AnimationSelectorProps {
	disabled?: boolean;
}

const FetchLoader = () => (
	<div className="flex justify-center">
		<Spinner label="Loading templates" />
	</div>
);

const SkeletonLoader = () => (
	<div className="flex justify-center">
		<CircularProgress label="Bring your character to life..." />
	</div>
);

export const AnimationSelector: React.FC<AnimationSelectorProps> = ({
	disabled = false,
}) => {
	const [templates, setTemplates] = useState<AnimationTemplate[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isApplying, setIsApplying] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const { isOpen, onOpen, onOpenChange } = useDisclosure();
	const { setCharacter, character } = useCharacter();
	const { setStep } = useCharacterCreationContext();
	const mp = getMixPanelClient();
	const { refreshUserData, userData, isAuthenticated } = useAuth();

	useEffect(() => {
		const fetchTemplates = async () => {
			try {
				const response = await fetch("/api/v1/template-animation");
				if (!response.ok) throw new Error("Failed to fetch templates");
				const data = await response.json();
				setTemplates(data);
			} catch (error) {
				console.error("Error:", error);
				makeToastError({ message: "Failed to load animation templates" });
			} finally {
				setIsLoading(false);
			}
		};
		fetchTemplates();
	}, []);

	const handleSelect = useCallback(
		async (templateId: string, onClose: () => void) => {
			if (!character?.isReadyForAnimation || !character.id) {
				makeToastError({ message: "Character not ready for animation" });
				return;
			}

			setIsApplying(true);
			try {
				const response = await fetch("/api/v1/template-animation", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						animation_template_id: templateId,
						character_id: character.id,
					}),
				});

				if (!response.ok) throw new Error("Failed to apply animation");
				const data = await response.json();
				setCharacter((prev) => ({
					...prev,
					animatedCharacter: data.animated_character_url,
					animatedAudio: data.audio_url,
					animationTemplateId: templateId,
				}));
				// setCharacter((prev) =>
				// 	prev
				// 		? {
				// 				...prev,
				// 				animatedCharacter: data.animated_character_url,
				// 				animatedAudio: data.audio_url,
				// 				animationTemplateId: templateId,
				// 		  }
				// 		: null
				// );

				setStep(CharacterCreationStep.motion);
				onClose();
				mp.track(EventName.SelectMotionTransfer, { template: templateId });
				window.scrollTo(0, 0);
				if (isAuthenticated) await refreshUserData(true);
			} catch (error) {
				console.error("Error:", error);
				makeToastError({ message: "Failed to apply animation" });
			} finally {
				setIsApplying(false);
			}
		},
		[
			character?.id,
			character?.isReadyForAnimation,
			isAuthenticated,
			mp,
			refreshUserData,
			setCharacter,
			setStep,
		]
	);

	const groupedTemplates = templates.reduce<
		Record<string, AnimationTemplate[]>
	>((acc, template) => {
		const category = template.category || "Other";
		if (!acc[category]) acc[category] = [];
		acc[category].push(template);
		return acc;
	}, {});

	const selectedTemplate = templates.find(
		(t) => t.id === character?.animationTemplateId
	);

	if (error) {
		return (
			<div className="text-danger">
				<p>Error: {error}</p>
				<p>Please try again later or contact support.</p>
			</div>
		);
	}

	return (
		<div className="flex flex-col items-center gap-4">
			<h2 className="font-medium">Use a template motion</h2>

			{isLoading ? (
				<FetchLoader />
			) : isApplying ? (
				<SkeletonLoader />
			) : (
				<>
					<Tooltip
						content={"No credits left"}
						isDisabled={!isAuthenticated || userData?.credits !== 0}
					>
						<div>
							<Button
								onPress={onOpen}
								isDisabled={
									disabled ||
									!character?.isReadyForAnimation ||
									(isAuthenticated && userData?.credits === 0)
								}
								variant="bordered"
								className="min-w-[200px] font-medium border-white"
							>
								{selectedTemplate?.name ||
									`Select a motion ${isAuthenticated && "— 1 credit"}`}
							</Button>
						</div>
					</Tooltip>

					<Modal
						isOpen={isOpen}
						onOpenChange={onOpenChange}
						size="lg"
						backdrop="blur"
						scrollBehavior="inside"
						classNames={{
							backdrop:
								"bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
							body: "py-2", // Reduced padding vertically
							base: "max-h-[50vh]", // Reduced maximum height
						}}
						closeButton={
							<Button isIconOnly variant="light">
								<IoClose size={24} />
							</Button>
						}
					>
						<ModalContent className="px-2 py-4">
							{(onClose) => (
								<>
									<ModalHeader className="flex flex-col gap-1 font-semibold py-2 text-2xl">
										Select Animation Template {isAuthenticated && "— 1 credit"}
									</ModalHeader>

									<ModalBody>
										<ScrollShadow hideScrollBar>
											{Object.entries(groupedTemplates).map(
												([category, items]) => (
													<div key={category} className="mb-2">
														<h4 className="text-medium font-medium mb-2">
															{category}
														</h4>
														<div className="grid grid-cols-1 md:grid-cols-2 gap-3">
															{items.map((template) => (
																<Tooltip
																	key={template.id}
																	content={template.description}
																	placement="top"
																	delay={0}
																	closeDelay={0}
																	className="max-w-xs"
																>
																	<Card
																		isPressable
																		isHoverable
																		onPress={async () =>
																			await handleSelect(template.id, onClose)
																		}
																		shadow="none"
																		className={`border ${
																			template.id ===
																			character?.animationTemplateId
																				? "border-primary"
																				: "border-transparent"
																		} ${styles.background} p-0.5`}
																	>
																		<CardBody className="p-2">
																			<div className="flex items-center gap-2">
																				{template.new && (
																					<Chip
																						variant="shadow"
																						size="sm"
																						color="secondary"
																						classNames={{
																							base: "h-4 min-h-unit-1",
																							content:
																								"drop-shadow shadow-black text-white text-xs px-2 py-0",
																						}}
																					>
																						New
																					</Chip>
																				)}
																				<h4 className="font-light flex-grow text-sm">
																					{template.name}
																				</h4>
																				{template.has_audio && (
																					<MdOutlineVolumeUp className="text-default-500" />
																				)}
																			</div>
																		</CardBody>
																	</Card>
																</Tooltip>
															))}
														</div>
													</div>
												)
											)}
										</ScrollShadow>
									</ModalBody>
								</>
							)}
						</ModalContent>
					</Modal>
				</>
			)}
		</div>
	);
};
