import { useCharacter } from "@/contexts";
import { generateMTLContent } from "@/utils";
import axios from "axios";
import saveAs from "file-saver";
import JSZip from "jszip";
import { useCallback, useState } from "react";
import { DownloadType } from "./types";
import { useAuth } from "@/components/Auth";

export const useDownloadCharacter = () => {
	const { character } = useCharacter();
	const [isDownloading, setIsDownloading] = useState(false);
	const { refreshUserData } = useAuth();

	const handleDownload = useCallback(
		async (downloadType: DownloadType) => {
			setIsDownloading(true);
			if (!character?.id || !character?.objectTexture) {
				console.error("Character ID or texture URL is missing");
				setIsDownloading(false);
				return;
			}

			try {
				const zip = new JSZip();

				// Common function to fetch and add file to zip
				const addFileToZip = async (url: string, filename: string) => {
					const response = await fetch(url);
					const blob = await response.blob();
					zip.file(filename, blob);
				};

				// Add texture file
				await addFileToZip(character.objectTexture, "material_0.png");

				// Add specific files based on download type
				switch (downloadType) {
					case DownloadType.Obj:
						if (!character.object) throw new Error("Object URL is missing");
						await addFileToZip(character.object, `skibs-${character.id}.obj`);
						zip.file("material.mtl", generateMTLContent());
						break;
					case DownloadType.Rigged:
						if (!character.riggedObject)
							throw new Error("Rigged object URL is missing");
						await addFileToZip(
							character.riggedObject,
							`skibs-${character.id}.fbx`
						);
						break;
					case DownloadType.Animation:
						if (!character.animatedCharacter)
							throw new Error("Animated character URL is missing");
						await addFileToZip(
							character.animatedCharacter,
							`skibs-${character.id}-animation.fbx`
						);
						if (character.animatedAudio) {
							await addFileToZip(character.animatedAudio, "audio.mp3");
						}
						break;
				}

				// Generate and download ZIP file
				const content = await zip.generateAsync({ type: "blob" });
				saveAs(content, `skibs-${character.id}-${downloadType}.zip`);

				// Send download information to server
				const body = new FormData();
				body.append(
					"is_animation_download",
					(downloadType === DownloadType.Animation).toString()
				);
				body.append("is_obj_download", (downloadType === "obj").toString());
				body.append(
					"is_rigged_download",
					(downloadType === DownloadType.Rigged).toString()
				);
				body.append("iteration_nb", (character.iterationNb ?? 0).toString());

				const response = await axios.post(
					`/api/v1/user/download/character/${character.id}`,
					body
				);

				if (response.status !== 200) {
					throw new Error(`Server responded with status: ${response.status}`);
				}
				await refreshUserData(true);
			} catch (error) {
				console.error(`Error downloading ${downloadType} files:`, error);
			} finally {
				setIsDownloading(false);
			}
		},
		[
			character?.animatedAudio,
			character?.animatedCharacter,
			character?.id,
			character?.iterationNb,
			character?.object,
			character?.objectTexture,
			character?.riggedObject,
			refreshUserData,
		]
	);

	return { isDownloading, handleDownload };
};
