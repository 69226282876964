import { ControlPosition } from "react-draggable";

export type Keypoint = {
	chin: ControlPosition;
	larm: ControlPosition;
	rarm: ControlPosition;
	lelbow: ControlPosition;
	relbow: ControlPosition;
	lknee: ControlPosition;
	rknee: ControlPosition;
	groin: ControlPosition;
	[key: string]: ControlPosition;
};

export const getColorForKeypoint = (key: string) => {
	const colors: { [key: string]: string } = {
		chin: "cyan",
		larm: "lime",
		rarm: "lime",
		lelbow: "yellow",
		relbow: "yellow",
		lknee: "orange",
		rknee: "orange",
		groin: "pink",
	};
	return colors[key] || "white";
};

export const getAbbreviationForKeypoint = (key: string) => {
	const abbreviations: { [key: string]: string } = {
		chin: "Chin",
		larm: "Left wrist",
		rarm: "Right wrist",
		lelbow: "Left elbow",
		relbow: "Right elbow",
		lknee: "Left knee",
		rknee: "Right knee",
		groin: "Groin",
	};
	return abbreviations[key] || key;
};
