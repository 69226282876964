import { CharacterProcessStep, useCharacterCreationContext } from "@/contexts";
import { ProcessingCard } from "@/components";

export const CharacterProcessing: React.FC = () => {
	const { processStep, progress } = useCharacterCreationContext();

	let progressText = "";
	let progressDescription = "";
	switch (processStep) {
		case CharacterProcessStep.modeling:
			progressText = "modeling ";
			progressDescription = "This process can take up to 3 minutes";
			break;
		case CharacterProcessStep.rigging:
			progressText = "rigging ";
			progressDescription = "This process takes about 1 minute";
			break;

		case CharacterProcessStep.redo:
			progressText = "redoing ";
			progressDescription =
				"This process takes about 1 minute for fast redo and 3 minutes for complete redo";
			break;

		default:
			break;
	}

	return (
		<ProcessingCard
			progress={progress}
			progressText={progressText}
			progressDescription={progressDescription}
		/>
	);
};
