"use client";

import { IoCloudUploadOutline } from "react-icons/io5";
import { StepCard } from "../../components/StepCard/StepCard";
import { StepCardIcon } from "@/components";

export const CharacterImporter: React.FC = () => {
	const title = "Import your character";
	const description = "Upload your character 3D model here (obj, fbx, glb)";
	return (
		<StepCard title={title} description={description}>
			<div className="flex gap-5 w-full items-center my-auto">
				<StepCardIcon icon={<IoCloudUploadOutline size={54} />} />
			</div>
		</StepCard>
	);
};
