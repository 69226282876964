import { EventName, getMixPanelClient } from "@/app/mixpanel";
import { Button } from "@nextui-org/react";
import React, { useCallback, useEffect } from "react";

type AnimationButtonProps = {
	onAnimationStart: () => void;
	disabled: boolean;
	disabledText: string;
	text: string;
	icon: React.ReactNode;
	disabledIcon?: React.ReactNode;
	isLoading?: boolean;
};

export const AnimationButton: React.FC<AnimationButtonProps> = ({
	onAnimationStart,
	disabled,
	disabledText,
	text,
	icon,
	disabledIcon,
	isLoading,
}) => {
	const mp = getMixPanelClient();

	const handleAction = useCallback(
		(e?: any, usingSpaceBar: boolean = false) => {
			if (!disabled && !isLoading) {
				mp.track(EventName.StartAnimation, {
					type: text,
					isTriggeredWithSpaceBar: usingSpaceBar,
				});
				onAnimationStart();
			}
		},
		[disabled, isLoading, mp, onAnimationStart, text]
	);

	useEffect(() => {
		const handleKeyPress = (event: KeyboardEvent) => {
			if (
				event.code === "Space" &&
				document.activeElement?.tagName !== "BUTTON"
			) {
				event.preventDefault(); // Prevent page scroll on space press
				handleAction(undefined, true);
			}
		};

		window.addEventListener("keydown", handleKeyPress);

		return () => {
			window.removeEventListener("keydown", handleKeyPress);
		};
	}, [handleAction]);

	return (
		<div className="absolute bottom-4 left-1/2 -translate-x-1/2">
			<Button
				variant="bordered"
				className="rounded-full bg-gray-900/70 h-8 min-h-8 sm:h-12 sm:min-h-10 md:h-10 md:min-h-10 text-sm sm:text-medium"
				startContent={disabledIcon && disabled ? disabledIcon : icon}
				onPress={handleAction}
				isDisabled={disabled}
				isLoading={isLoading}
			>
				<span className="font-semibold text-sm sm:text-medium">
					{disabled ? disabledText : text}
				</span>
			</Button>
		</div>
	);
};
