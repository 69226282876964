import { useState, useEffect, useCallback, useMemo } from "react";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader.js";
import {
	GLTFLoader,
	GLTFParser,
} from "three/examples/jsm/loaders/GLTFLoader.js";
import {
	TextureLoader,
	Object3D,
	Box3,
	Vector3,
	Mesh,
	AnimationClip,
	Group,
	Camera,
	ShaderMaterial,
	UniformsUtils,
	Texture,
	Color,
	DoubleSide,
} from "three";

import { useCharacter, useCharacterCreationContext } from "@/contexts";
import { CharacterCreationStep } from "@/hooks/useCharacterCreation";

// Toon Shader
const vertexShader = `
  #include <skinning_pars_vertex>
  varying vec2 vUv;
  varying vec3 vNormal;

  void main() {
    #include <skinbase_vertex>
    #include <begin_vertex>
    #include <skinning_vertex>
    #include <project_vertex>
    
    vUv = uv;
    vNormal = normalize(normalMatrix * normal);
  }
`;

const fragmentShader = `
  uniform sampler2D map;
  uniform vec3 color;
  varying vec2 vUv;
  varying vec3 vNormal;

  void main() {
    vec4 texColor = texture2D(map, vUv);
    vec3 adjustedColor = pow(texColor.rgb * color, vec3(1.2));
    float intensity = dot(vNormal, vec3(0.5, 0.5, 0.5));
    vec3 toonColor = mix(adjustedColor * 0.7, adjustedColor, smoothstep(0.3, 0.7, intensity));
    gl_FragColor = vec4(toonColor, texColor.a);
  }
`;

interface GLTF {
	animations: AnimationClip[];
	scene: Group;
	scenes: Group[];
	cameras: Camera[];
	asset: {
		copyright?: string | undefined;
		generator?: string | undefined;
		version?: string | undefined;
		minVersion?: string | undefined;
		extensions?: any;
		extras?: any;
	};
	parser: GLTFParser;
	userData: Record<string, any>;
}

export const useModelLoader = (
	fileType: "obj" | "fbx" | "gltf",
	onProgress?: (progress: number) => void
) => {
	const [modelState, setModelState] = useState({
		model: null as Object3D | null,
		boundingBox: new Box3(),
		center: new Vector3(),
		scale: 1,
	});
	const [error, setError] = useState<string | null>(null);

	const { character } = useCharacter();
	const { step } = useCharacterCreationContext();

	const updateModelProps = useCallback((object: Object3D) => {
		console.log("Updating model props");
		const box = new Box3().setFromObject(object);
		const size = new Vector3();
		box.getSize(size);
		const newScale = 1 / size.length();

		object.updateMatrix();
		object.updateMatrixWorld(true);

		setModelState({
			model: object,
			boundingBox: box,
			center: box.getCenter(new Vector3()),
			scale: newScale,
		});
	}, []);

	const applyCustomShader = useCallback(
		(object: Object3D, texture: Texture) => {
			console.log("Applying custom shader");
			object.traverse((child: Object3D) => {
				if (child instanceof Mesh) {
					const uniforms = UniformsUtils.merge([
						{ map: { value: texture } },
						{ color: { value: new Color(1, 1, 1) } },
					]);

					const material = new ShaderMaterial({
						uniforms: uniforms,
						vertexShader: vertexShader,
						fragmentShader: fragmentShader,
						side: DoubleSide,
					});
					child.material = material;
					child.material.needsUpdate = true;

					child.castShadow = true;
					child.receiveShadow = true;
				}
			});
		},
		[]
	);

	const fileUrl = useMemo(() => {
		if (!character) return undefined;
		switch (fileType) {
			case "obj":
				return character.object;
			case "fbx":
				return step === CharacterCreationStep.motion
					? character.animatedCharacter
					: character.jumpingCharacter;
			case "gltf":
				return character.object;
			default:
				return undefined;
		}
	}, [character, fileType, step]);

	const loadModel = useCallback(() => {
		if (!fileUrl) {
			setError("Invalid file url");
			return;
		}

		let loader: OBJLoader | FBXLoader | GLTFLoader;

		switch (fileType) {
			case "obj":
				loader = new OBJLoader();
				break;
			case "fbx":
				loader = new FBXLoader();
				break;
			default:
				setError("Invalid file type");
				return;
		}

		loader.load(
			fileUrl,
			(object: Object3D | any) => {
				if (character?.objectTexture) {
					const textureLoader = new TextureLoader();
					textureLoader.load(
						character.objectTexture,
						(texture) => {
							applyCustomShader(object, texture);
							updateModelProps(object);
						},
						undefined,
						(error) => {
							console.error(
								"An error happened while loading the texture",
								error
							);
						}
					);
				} else {
					updateModelProps(object);
				}
			},
			(xhr) => {
				if (onProgress) {
					onProgress((xhr.loaded / xhr.total) * 100);
				}
			},
			(error) => {
				console.error("An error happened while loading the model", error);
			}
		);
	}, [
		fileUrl,
		fileType,
		character?.objectTexture,
		applyCustomShader,
		updateModelProps,
		onProgress,
	]);

	useEffect(() => {
		loadModel();
	}, [loadModel]);

	return modelState;
};
