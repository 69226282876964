"use client";
import React from "react";
import { useCharacterCreationContext } from "../../contexts/CharacterCreationContext";
import {
	ImageImporter,
	CharacterCreationStep,
	ImageUploader,
} from "@/app/character";
import { TemplateSelector } from "./TemplateSelector";
import { CharacterRigging } from "./steps/rigging/CharacterRigging";
import { RiggingProvider } from "./contexts/RiggingContext";
import { CharacterFinalView } from "./steps/CharacterFinalView";
import { CharacterMotionTransfer } from "./steps/motion-transfer/CharacterMotionTransfer";
import { MeshPreview } from "./steps/mesh-preview/MeshPreview";
import { CharacterPreviewProvider } from "@/contexts";
import { CharacterProcessing } from "./steps/CharacterProcessing";
import { CharacterPreviewRenders } from "./steps/preview-renders/CharacterPreviewRenders";

export const CharacterCreation: React.FC = () => {
	const { step } = useCharacterCreationContext();

	const renderContent = () => {
		switch (step) {
			case CharacterCreationStep.initial:
				return (
					<div className="inline-block">
						<div className="mx-auto max-w-[400px] flex flex-col gap-4 items-center">
							<div>
								<ImageImporter />
							</div>
							<div>
								<TemplateSelector />
							</div>
						</div>
					</div>
				);
			case CharacterCreationStep.upload:
				return <ImageUploader />;
			case CharacterCreationStep.processing:
				return <CharacterProcessing />;
			case CharacterCreationStep.previewRenders:
				return <CharacterPreviewRenders />;
			case CharacterCreationStep.rigging:
				return (
					<CharacterPreviewProvider>
						<RiggingProvider>
							<CharacterRigging />
						</RiggingProvider>
					</CharacterPreviewProvider>
				);
			case CharacterCreationStep.previewMesh:
				return (
					<CharacterPreviewProvider>
						<MeshPreview />
					</CharacterPreviewProvider>
				);
			case CharacterCreationStep.previewRigging:
				return (
					<CharacterPreviewProvider>
						<CharacterFinalView />
					</CharacterPreviewProvider>
				);
			case CharacterCreationStep.motion:
				return (
					<CharacterPreviewProvider>
						<CharacterMotionTransfer />
					</CharacterPreviewProvider>
				);
			case CharacterCreationStep.error:
				return <div>Something went wrong</div>;
			default:
				return null;
		}
	};

	return <div className="mx-auto flex flex-col gap-4">{renderContent()}</div>;
};
