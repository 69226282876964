"use client";
import React, { useEffect } from "react";
import { CharacterCreation } from "./CharacterCreation";
import { StudioCard } from "@/components";
import { useCharacter, useJob } from "@/contexts";
import { IoPersonOutline } from "react-icons/io5";

export const CharacterPanel: React.FC = () => {
	const panelTitle = "Step 1: Character";
	const { character } = useCharacter();


	const displayBorder = !character?.isReadyForAnimation;

	const helpMessage =
		"Create your character from an image or select an already generated character.";

	return (
		<StudioCard
			title={panelTitle}
			icon={<IoPersonOutline className="w-7 h-7 sm:w-9 sm:h-9" />}
			helpMessage={helpMessage}
			isStepActive={true}
			displayBorder={displayBorder}
			tooltipImage="/character.png"
			tooltipLink="https://ooma-hq.notion.site/Preparing-Input-for-3D-Character-Generation-with-Skibs-55fe16ab9fbb472aa644fe7365670d00"
		>
			<div>
				<CharacterCreation />
			</div>
		</StudioCard>
	);
};
