"use client";

import { useEffect, useState } from "react";

interface TopLoadingBarProps {
	color?: string;
	height?: number;
	waitForContent?: boolean;
}

export const TopLoadingBar = ({
	color = "var(--primary)",
	height = 4,
	waitForContent = true,
}: TopLoadingBarProps) => {
	const [progress, setProgress] = useState(0);
	const [isVisible, setIsVisible] = useState(true);

	useEffect(() => {
		let timeoutIds: NodeJS.Timeout[] = [];

		const startProgress = () => {
			// Quick initial progress
			timeoutIds.push(setTimeout(() => setProgress(30), 100));
			timeoutIds.push(setTimeout(() => setProgress(45), 250));
			timeoutIds.push(setTimeout(() => setProgress(60), 500));

			// Slower middle progress
			timeoutIds.push(setTimeout(() => setProgress(75), 750));
			timeoutIds.push(setTimeout(() => setProgress(85), 1000));

			// Very slow final progress
			timeoutIds.push(setTimeout(() => setProgress(90), 1500));
			timeoutIds.push(setTimeout(() => setProgress(95), 2500));

			if (!waitForContent) {
				timeoutIds.push(
					setTimeout(() => {
						setProgress(100);
						timeoutIds.push(setTimeout(() => setIsVisible(false), 200));
					}, 3000)
				);
			}
		};

		startProgress();

		return () => {
			timeoutIds.forEach(clearTimeout);
		};
	}, [waitForContent]);

	// Function to complete the loading bar
	const complete = () => {
		setProgress(100);
		setTimeout(() => setIsVisible(false), 200);
	};

	if (!isVisible) return null;

	return (
		<div className="fixed top-0 left-0 right-0 z-[999]" style={{ height }}>
			<div
				className="h-full transition-all duration-300 ease-out rounded-r-full"
				style={{
					width: `${progress}%`,
					backgroundColor: color,
					boxShadow: `0 0 ${height * 2}px ${color}`,
				}}
			/>
		</div>
	);
};
