// components/Auth/AuthErrorBoundary.tsx
"use client";

import { Suspense } from "react";
import { useAuthErrorInterceptor } from "./useAuthErrorInterceptor";

function AuthErrorInterceptor() {
	useAuthErrorInterceptor();
	return null;
}

export function AuthErrorBoundary({ children }: { children: React.ReactNode }) {
	return (
		<>
			<Suspense fallback={null}>
				<AuthErrorInterceptor />
			</Suspense>
			{children}
		</>
	);
}
