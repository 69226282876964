import { EventName, getMixPanelClient } from "@/app/mixpanel";
import { Card, CardHeader, Spinner } from "@nextui-org/react";
import React, { useState } from "react";
import Image from "next/image";
import {
	useCharacterCreationContext,
	useCharacterRedoContext,
} from "@/contexts";

interface RenderImageProps {
	redoType: string;
	url: string;
	index: number;
	isConfirmingRedoType: boolean;
	setIsConfirmingRedoType: (value: boolean) => void;
}

export const RenderImage: React.FC<RenderImageProps> = ({
	redoType,
	url,
	index,
	isConfirmingRedoType,
	setIsConfirmingRedoType,
}) => {
	const mp = getMixPanelClient();
	const [localIsConfirmingRedoType, setLocalIsConfirmingRedoType] =
		useState(false);

	const { confirmQuickRedo, getQuickRedoData } = useCharacterCreationContext();
	const { addToCharacters } = useCharacterRedoContext();
	return (
		<Card
			key={redoType}
			isPressable={!isConfirmingRedoType && !localIsConfirmingRedoType}
			onPress={async () => {
				mp.track(EventName.ConfirmQuickRedo, {
					quickRedo: redoType,
				});
				setIsConfirmingRedoType(true);
				setLocalIsConfirmingRedoType(true);
				console.log("Confirming quick redo", redoType);
				await confirmQuickRedo(redoType);
				const quickRedoData = getQuickRedoData(redoType);
				addToCharacters(quickRedoData.object, quickRedoData.texture);
				setIsConfirmingRedoType(false);
				setLocalIsConfirmingRedoType(false);
			}}
		>
			<CardHeader className="absolute left-3">
				<h5 className="text-lg font-semibold">{index + 1}</h5>
			</CardHeader>
			{localIsConfirmingRedoType && (
				<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
					<Spinner size="lg" />
				</div>
			)}
			<Image
				src={url}
				alt="Character Preview"
				width={300}
				height={300}
				className={`rounded-lg m-auto ${
					localIsConfirmingRedoType ? "opacity-50" : ""
				}`}
			/>
		</Card>
	);
};
