import {
	useCharacter,
	useCharacterCreationContext,
	useCharacterRedoContext,
} from "@/contexts";
import { CharacterCreationView } from "../../viewer/CharacterCreationView";
import { Button } from "@nextui-org/react";
import { IoRadioButtonOnOutline, IoRefreshOutline } from "react-icons/io5";
import { useRouter } from "next/navigation";
import { EventName, getMixPanelClient } from "@/app/mixpanel";

export const CharacterMotionTransfer: React.FC = () => {
	const { cancelCreation } = useCharacterCreationContext();
	const { clearCharacters } = useCharacterRedoContext();
	const { character } = useCharacter();
	const isAnimatedCharacterReady = !!character?.animatedCharacter;
	// // const handleDownload = () => {
	// // 	const downloadUrl = `/api/v1/download-video?url=${encodeURIComponent(
	// // 		character?.animatedVideoUrl!
	// // 	)}`;
	// // 	window.location.href = downloadUrl;
	// // };
	const router = useRouter();
	const mp = getMixPanelClient();
	return (
		<div className="text-center space-y-4 -mt-2">
			<h3 className="text-medium sm:text-xl font-semibold">
				Motion transferred!
			</h3>

			<CharacterCreationView showAnimation={true} />

			<div className="flex flex-col gap-2 items-center justify-center">
				<Button
					variant="light"
					startContent={<IoRefreshOutline size={20} />}
					size="lg"
					onPress={() => {
						mp.track(EventName.CancelCreation, {
							step: "Character MotionTransfer",
						});
						clearCharacters();
						cancelCreation();
					}}
					className="h-8 min-h-8 sm:h-12 sm:min-h-12 md:h-12 md:min-h-12 text-sm sm:text-medium rounded-[0.6rem] sm:rounded-2xl"
				>
					New character
				</Button>
				<Button
					radius="lg"
					className="block sm:hidden bg-gradient-to-r from-[#FF44CC] via-[#967DE7] to-[#3BAEFF] h-8 min-h-8 sm:h-12 sm:min-h-12 md:h-12 md:min-h-12 text-sm sm:text-medium rounded-[0.6rem] sm:rounded-2xl w-1/2"
					isDisabled={!isAnimatedCharacterReady}
					onPress={() => {
						mp.track(EventName.ExportVideo);
						router.push("/record");
					}}
				>
					Export video
				</Button>
			</div>
		</div>
	);
};
