import { EventName, getMixPanelClient } from "@/app/mixpanel";
import {
	useCharacterCreationContext,
	useCharacterRedoContext,
} from "@/contexts";
import { Badge, Button, Tooltip } from "@nextui-org/react";
import { IoCloseOutline, IoRefreshOutline } from "react-icons/io5";
import styles from "@/components/StepCard/StepCard.module.css";
import { useState } from "react";
import { RenderImage } from "./RenderImage";
import { useAuth } from "@/components/Auth";
export const CharacterPreviewRenders: React.FC = () => {
	const { cancelCreation, renderQuickRedoImages, getQuickRedoData } =
		useCharacterCreationContext();
	const { redoCharacter, addToCharacters, clearCharacters } =
		useCharacterRedoContext();
	const mp = getMixPanelClient();
	const [isConfirming, setIsConfirming] = useState(false);
	const { userData } = useAuth();
	return (
		<div className="text-center space-y-2 -mt-4">
			<h3 className="text-medium sm:text-xl font-semibold">
				Pick the best character generation
			</h3>
			<p className="text-sm sm:text-lg">
				Check which 3D generation is the best. If none are good (bad morphology
				/ bad texture) you can redo the generation 2 more times.
			</p>

			<div
				className={`grid grid-col-1  sm:grid-cols-2 grid-rows-2 gap-5 ${styles.background} p-6 rounded-3xl`}
			>
				{renderQuickRedoImages &&
					Object.entries(renderQuickRedoImages).map(([key, url], index) => (
						<RenderImage
							key={key}
							redoType={key}
							url={url}
							index={index}
							isConfirmingRedoType={isConfirming}
							setIsConfirmingRedoType={setIsConfirming}
						/>
					))}
			</div>

			<div className="flex flex-col sm:flex-row gap-x-3 gap-y-1 w-full justify-center">
				<Button
					variant="light"
					startContent={<IoCloseOutline size={15} />}
					size="lg"
					onPress={() => {
						mp.track(EventName.CancelCreation, {
							step: "Character MeshPreview",
						});
						clearCharacters();
						cancelCreation();
					}}
					className="h-8 min-h-8 sm:h-12 sm:min-h-12 md:h-12 md:min-h-12 text-sm sm:text-medium rounded-[0.6rem] sm:rounded-2xl"
				>
					Cancel
				</Button>
				<Tooltip
					content={userData?.credits! < 2 ? "No credits left" : "2 credits"}
					size="lg"
				>
					<div>
						<Button
							startContent={<IoRefreshOutline size={22} />}
							size="lg"
							className="h-8 min-h-8 sm:h-12 sm:min-h-12 md:h-12 md:min-h-12 text-sm sm:text-medium rounded-[0.6rem] sm:rounded-2xl"
							variant="faded"
							onPress={async () => {
								mp.track(EventName.RedoCharacter, {
									redo: "bad_morphology",
								});
								const quickRedoData = getQuickRedoData();
								addToCharacters(quickRedoData.object, quickRedoData.texture);
								await redoCharacter("bad_morphology");
							}}
							isDisabled={userData?.credits! < 2}
						>
							Redo character
						</Button>
					</div>
				</Tooltip>
			</div>
		</div>
	);
};
