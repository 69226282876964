import { Button, Card, CardBody } from "@nextui-org/react";
import Image from "next/image";
import styles from "@/components/StepCard/StepCard.module.css";
import { IoArrowBackOutline, IoSparklesOutline } from "react-icons/io5";
import { useCharacter, useCharacterCreationContext } from "@/contexts";
import { EventName, getMixPanelClient } from "@/app/mixpanel";

export const ImageUploader: React.FC = () => {
	const { character } = useCharacter();
	const { cancelCreation, startGeneration } = useCharacterCreationContext();
	const mp = getMixPanelClient();
	return (
		<div className="text-center space-y-4 -mt-2">
			<h3 className="text-medium sm:text-xl font-semibold">
				Ready to start the generation?
			</h3>
			<Card className={`${styles.background} p-1`} shadow="none">
				<CardBody className="">
					<Image
						src={character?.image ?? ""}
						alt={""}
						width={200}
						height={200}
						className="rounded-lg mx-auto"
					/>
				</CardBody>
			</Card>
			<div className="space-x-3">
				<Button
					variant="light"
					startContent={<IoArrowBackOutline size={15} />}
					onPress={() => {
						mp.track(EventName.CancelCreation, {
							step: "Character ImageUploader",
						});
						cancelCreation();
					}}
					size="lg"
					className="h-8 min-h-8 sm:h-12 sm:min-h-12 md:h-12 md:min-h-12 text-sm sm:text-medium rounded-[0.6rem] sm:rounded-2xl"
				>
					Cancel
				</Button>
				<Button
					startContent={<IoSparklesOutline size={15} />}
					size="lg"
					onPress={() => {
						mp.track(EventName.StartCreation, {
							step: "Character",
						});
						startGeneration();
					}}
					className="h-8 min-h-8 sm:h-12 sm:min-h-12 md:h-12 md:min-h-12 text-sm sm:text-medium rounded-[0.6rem] sm:rounded-2xl"
				>
					Generate!
				</Button>
			</div>
		</div>
	);
};
