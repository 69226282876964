import { useState, useEffect, useCallback } from "react";
import { AnimationMixer, Object3D, LoopOnce, AnimationAction } from "three";

export const useAnimation = (
	model: Object3D | null,
	fileType: "obj" | "fbx" | "gltf"
) => {
	const [mixer, setMixer] = useState<AnimationMixer | null>(null);
	const [currentAction, setCurrentAction] = useState<AnimationAction | null>(
		null
	);

	useEffect(() => {
		if (model && (fileType === "fbx" || fileType === "gltf")) {
			const newMixer = new AnimationMixer(model);
			setMixer(newMixer);
			const animation = model.animations[0];

			if (animation) {
				console.log("Setting animation and current action");
				const action = newMixer.clipAction(animation);
				action.setLoop(LoopOnce, 1);
				action.clampWhenFinished = true;
				setCurrentAction(action);
			} else {
				console.warn("Animation not found in the file.");
			}
		}
	}, [model, fileType]);

	const playAnimation = useCallback(() => {
		if (currentAction) {
			currentAction.reset();
			currentAction.play();
		}
	}, [currentAction]);

	const stopAnimation = useCallback(() => {
		if (currentAction) {
			currentAction.stop();
		}
	}, [currentAction]);

	return { mixer, currentAction, playAnimation, stopAnimation };
};
