import { useRef, useEffect, useState } from "react";

export const useAudio = (audioURL: string | undefined) => {
	const audioRef = useRef<HTMLAudioElement | null>(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [isMuted, setIsMuted] = useState(false);

	useEffect(() => {
		if (audioURL) {
			audioRef.current = new Audio(audioURL);
		}
		return () => {
			if (audioRef.current) {
				console.log("cleaning up audio");
				audioRef.current.pause();
				audioRef.current = null;
			}
		};
	}, [audioURL]);

	const play = () => {
		if (audioRef.current) {
			audioRef.current.play();
			setIsPlaying(true);
		}
	};

	const stop = () => {
		if (audioRef.current) {
			audioRef.current.pause();
			audioRef.current.currentTime = 0;
			setIsPlaying(false);
		}
	};

	const mute = () => {
		if (audioRef.current) {
			audioRef.current.muted = true;
			setIsMuted(true);
		}
	};

	const unmute = () => {
		if (audioRef.current) {
			audioRef.current.muted = false;
			setIsMuted(false);
		}
	};

	return { play, stop, mute, unmute, isPlaying, isMuted };
};
