import React, { ComponentType } from "react";
import { Button } from "@nextui-org/react";
import { useAuth } from "./AuthContext";

// Define the props for the HOC
type WithAuthProps = {
	authMessage?: string;
	isAvailableForFree?: boolean;
};

export function withAuth<P extends object>(
	WrappedComponent: ComponentType<P>
): ComponentType<P & WithAuthProps> {
	const WithAuth: React.FC<P & WithAuthProps> = (props) => {
		const { isAuthenticated, openAuthModal } = useAuth();

		// FEATURE DISABLED: Free generations
		// const { freeGenerationCount } = useUserData();
		const { authMessage, isAvailableForFree, ...wrappedProps } = props;

		// Default message if not provided
		const defaultMessage = "You need to be signed in to use this feature.";

		const newProps = {
			...wrappedProps,
			isAvailableForFree,
		};
		// FEATURE DISABLED: Free generations
		// const isFree = isAvailableForFree && freeGenerationCount > 0;
		if (!isAuthenticated) {
			return (
				<div className="relative">
					<div className="opacity-50 pointer-events-none">
						<WrappedComponent {...(wrappedProps as P)} />
					</div>
					<div className="absolute inset-0 bg-black/50 rounded-2xl">
						{/* This div provides the overall dimming effect */}
					</div>
					<div
						className="absolute inset-x-0 bottom-0 top-12 flex justify-center rounded-2xl"
						style={{
							backdropFilter: "blur(1px)",
						}}
					>
						<div className="p-2 rounded-lg text-center ">
							<p className="mb-2 text-white">{authMessage || defaultMessage}</p>
							<Button color="secondary" onClick={() => openAuthModal()}>
								Sign in
							</Button>
						</div>
					</div>
				</div>
			);
		}

		return <WrappedComponent {...(newProps as P)} />;
	};

	return WithAuth;
}
