import React, { useRef, useEffect } from "react";
import { Card, CardBody } from "@nextui-org/react";
import { useAnimationCreationContext } from "@/contexts";
import styles from "@/components/StepCard/StepCard.module.css";

export const MotionVideo: React.FC = () => {
	const { videoSource } = useAnimationCreationContext();
	const videoRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		if (videoRef.current && videoSource) {
			const video = videoRef.current;

			if (videoSource instanceof File) {
				video.src = URL.createObjectURL(videoSource);
			} else if (typeof videoSource === "string") {
				video.src = videoSource;
			}

			return () => {
				if (video && videoSource instanceof File) {
					URL.revokeObjectURL(video.src);
				}
			};
		}
	}, [videoSource]);

	return (
		<Card className={`${styles.background} p-1`} shadow="none">
			<CardBody>
				{videoSource ? (
					<video
						ref={videoRef}
						controls
						preload="metadata"
						className="rounded-lg max-h-[50vh]"
						style={{ width: "100%", height: "auto" }}
					/>
				) : (
					<p>No video file selected</p>
				)}
			</CardBody>
		</Card>
	);
};
