// export type DownloadType = "obj" | "rigged" | "animation";

export enum DownloadType {
	Obj = "obj",
	Rigged = "rigged",
	Animation = "animation",
}

export interface DownloadConfig {
	type: DownloadType;
	tooltip: string;
}
