import { Button, Card, CardBody } from "@nextui-org/react";
import { IoArrowBackOutline, IoSparklesOutline } from "react-icons/io5";

import { useEffect, useRef } from "react";
import { useAnimationCreationContext } from "@/contexts";
import { MotionVideo } from "./MotionVideo";
import { EventName, getMixPanelClient } from "@/app/mixpanel";

export const VideoUploader: React.FC = () => {
	const { videoSource, cancelCreation, startMotionGeneration } =
		useAnimationCreationContext();
	const videoRef = useRef<HTMLVideoElement>(null);
	const mp = getMixPanelClient();

	useEffect(() => {
		const video = videoRef.current;
		if (video && videoSource) {
			if (videoSource instanceof File) {
				video.src = URL.createObjectURL(videoSource);
			} else {
				video.src = videoSource;
			}
			return () => {
				if (video && videoSource instanceof File) {
					URL.revokeObjectURL(video.src);
				}
			};
		}
	}, [videoSource]);

	return (
		<div className="text-center space-y-4 -mt-2">
			<h3 className="text-xl font-semibold">
				Ready to start the motion transfer?
			</h3>
			<MotionVideo />
			<div className="space-x-3">
				<Button
					variant="light"
					startContent={<IoArrowBackOutline size={15} />}
					onPress={() => {
						mp.track(EventName.CancelCreation, {
							step: "Animation VideoUploader",
						});
						cancelCreation();
					}}
					size="lg"
				>
					Cancel
				</Button>
				<Button
					startContent={<IoSparklesOutline size={15} />}
					size="lg"
					onPress={() => {
						mp.track(EventName.StartCreation, {
							step: "Animation",
						});
						startMotionGeneration();
					}}
					isDisabled={!videoSource}
				>
					Transfer motion!
				</Button>
			</div>
		</div>
	);
};
