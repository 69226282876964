import { Button, Tooltip } from "@nextui-org/react";
import { CharacterCreationView } from "../../viewer/CharacterCreationView";
import {
	IoArrowBack,
	IoArrowForwardOutline,
	IoCheckmark,
	IoChevronBack,
	IoChevronForward,
	IoCloseOutline,
} from "react-icons/io5";
import {
	useCharacterCreationContext,
	useCharacterRedoContext,
} from "@/contexts";
import { RedoDropdown } from "./RedoDropdown";
import { EventName, getMixPanelClient } from "@/app/mixpanel";
import { CharacterCreationStep } from "@/hooks/useCharacterCreation";

export const MeshPreview: React.FC = () => {
	const { startRigCharacter, setStep } = useCharacterCreationContext();
	const {
		previousCharacter,
		nextCharacter,
		currentCharacterIndex,
		charactersLength,
		removeFromCharacters,
	} = useCharacterRedoContext();

	const mp = getMixPanelClient();

	return (
		<div className="text-center space-y-2 -mt-4">
			<h3 className="text-medium sm:text-xl font-semibold">
				View your character
			</h3>
			<p className="text-sm sm:text-lg">
				Touch the character to view it from different angles. If it looks good,
				you can prepare it for animation.
			</p>

			<div className="relative">
				<CharacterCreationView showAnimation={false} />
				<div className="absolute top-4 right-4">{/* <RedoDropdown /> */}</div>
				<div className="absolute top-1/2 -translate-y-1/2 left-3">
					<Tooltip content="Previous character">
						<Button
							isIconOnly
							color="secondary"
							onPress={previousCharacter}
							isDisabled={currentCharacterIndex === 0}
							className="w-8 h-8 min-w-8 min-h-8 sm:w-10 sm:h-10 sm:min-w-10 sm:min-h-10 md:w-10 md:h-10 md:min-w-10 md:min-h-10"
						>
							<IoChevronBack className="w-5 h-5 md:w-6 md:h-6 lg:w-7 lg:h-7" />
						</Button>
					</Tooltip>
				</div>
				<div className="absolute top-1/2 -translate-y-1/2 right-3">
					<Tooltip content="Next character">
						<Button
							isIconOnly
							color="secondary"
							onPress={nextCharacter}
							isDisabled={currentCharacterIndex === charactersLength - 1}
							className="w-8 h-8 min-w-8 min-h-8 sm:w-10 sm:h-10 sm:min-w-10 sm:min-h-10 md:w-10 md:h-10 md:min-w-10 md:min-h-10"
						>
							<IoChevronForward className="w-5 h-5 md:w-6 md:h-6 lg:w-7 lg:h-7" />
						</Button>
					</Tooltip>
				</div>
			</div>

			<span className="select-none text-xs sm:text-medium">
				{currentCharacterIndex + 1} / {charactersLength}
			</span>

			<div className="space-x-3 space-y-1">
				<Button
					variant="light"
					startContent={<IoArrowBack size={20} />}
					size="lg"
					onPress={() => {
						mp.track(EventName.CancelQuickRedo);
						setStep(CharacterCreationStep.previewRenders);
						removeFromCharacters();
					}}
					className="h-8 min-h-8 sm:h-12 sm:min-h-12 md:h-12 md:min-h-12 text-sm sm:text-medium rounded-[0.6rem] sm:rounded-2xl"
				>
					Back
				</Button>
				<Button
					startContent={<IoCheckmark size={20} />}
					size="lg"
					onPress={startRigCharacter}
					className="h-8 min-h-8 sm:h-12 sm:min-h-12 md:h-12 md:min-h-12 text-sm sm:text-medium rounded-[0.6rem] sm:rounded-2xl"
				>
					Prepare animation
				</Button>
			</div>
		</div>
	);
};
