"use client";
import React from "react";
import { StudioCard } from "@/components";
import { CiExport } from "react-icons/ci";
import { useCharacter } from "@/contexts/CharacterContext";
import { Button } from "@nextui-org/react";
import { useRouter } from "next/navigation";
import { EventName, getMixPanelClient } from "../mixpanel";

export const ExportPanel: React.FC = () => {
	const panelTitle = "Step 3: Export";
	const helpMessage = "Export your animation video to mp4";
	const { character } = useCharacter();
	const isAnimatedCharacterReady = !!character?.animatedCharacter;
	const displayBorder = isAnimatedCharacterReady;
	const router = useRouter();
	const mp = getMixPanelClient();
	return (
		<StudioCard
			title={panelTitle}
			helpMessage={helpMessage}
			isStepActive={isAnimatedCharacterReady}
			icon={<CiExport className="w-7 h-7 sm:w-9 sm:h-9" />}
			displayBorder={displayBorder}
			animatedCharacter={character?.animatedCharacter}
			disableWhenAnimatedCharacterIsNull={true}
		>
			<div className="flex justify-center items-center w-full">
				<Button
					radius="lg"
					className="w-[150px] bg-gradient-to-r from-[#FF44CC] via-[#967DE7] to-[#3BAEFF]"
					isDisabled={!isAnimatedCharacterReady}
					onPress={() => {
						mp.track(EventName.ExportVideo);
						router.push("/record");
					}}
				>
					Export video
				</Button>
			</div>
		</StudioCard>
	);
};
