"use client";

import { EventName, getMixPanelClient } from "@/app/mixpanel";
import {
	Tooltip,
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@nextui-org/react";
import { useState, useEffect } from "react";
import { IoHelpCircleOutline } from "react-icons/io5";

interface StudioTooltipProps {
	content: React.ReactNode;
	title: string;
}

export const StudioTooltip: React.FC<StudioTooltipProps> = ({
	content,
	title,
}) => {
	const mp = getMixPanelClient();
	const [isTouchDevice, setIsTouchDevice] = useState(false);

	useEffect(() => {
		const detectTouch = () => {
			setIsTouchDevice(true);
			window.removeEventListener("touchstart", detectTouch);
		};

		window.addEventListener("touchstart", detectTouch);

		return () => {
			window.removeEventListener("touchstart", detectTouch);
		};
	}, []);

	const handleInteraction = () => {
		mp.track(EventName.HelpTooltip, {
			studioCardTitle: title,
		});
	};

	const TooltipContent = <IoHelpCircleOutline size={25} />;

	if (isTouchDevice) {
		return (
			<Popover>
				<PopoverTrigger>{TooltipContent}</PopoverTrigger>
				<PopoverContent>{content}</PopoverContent>
			</Popover>
		);
	}

	return (
		<Tooltip
			content={content}
			onOpenChange={(isOpen) => {
				if (isOpen) {
					handleInteraction();
				}
			}}
		>
			<span>{TooltipContent}</span>
		</Tooltip>
	);
};
