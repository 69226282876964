"use client";

import React from "react";
import cardStyles from "@/components/StepCard/StepCard.module.css";
import styles from "./ProcessingCard.module.css";
import { Card, CardBody, CircularProgress } from "@nextui-org/react";

interface ProcessingCardProps {
	progress: number;
	progressText: string;
	progressDescription?: string;
}

export const ProcessingCard: React.FC<ProcessingCardProps> = ({
	progress,
	progressText,
	progressDescription,
}) => {
	return (
		<Card className={`${cardStyles.background} p-4`} shadow="none">
			<CardBody className="w-full text-center space-y-5">
				<div className="mx-auto">
					<CircularProgress
						aria-label="Loading..."
						size="lg"
						value={progress * 100}
						color="warning"
						showValueLabel={true}
						classNames={{
							svg: "w-36 h-36",
							indicator: "stroke-white",
							track: "stroke-white/10",
							value: "text-3xl font-semibold text-white",
						}}
					/>
				</div>
				<div className={styles.animatedTextContainer}>
					<span
						className={`${styles.animatedText} text-2xl font-semibold text-white`}
					>
						{progressText}
					</span>
					<span className={styles.jumpingDots}>
						<span className={styles.dot}>•</span>
						<span className={styles.dot}>•</span>
						<span className={styles.dot}>•</span>
					</span>
				</div>
				<div>
					<span className="text-white/75 text-sm">{progressDescription}</span>
				</div>
			</CardBody>
		</Card>
	);
};
