"use client";
import React, {
	useRef,
	useEffect,
	useCallback,
	forwardRef,
	useImperativeHandle,
} from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { Mesh, Vector3, Box3, OrthographicCamera, Matrix4, Euler } from "three";
import { useModelLoader } from "./hooks/useModelLoader";
import { useAnimation } from "./hooks/useAnimation";
import { useCharacterPreviewContext } from "@/contexts";

export type MeshComponentProps = {
	fileType: "obj" | "fbx" | "gltf";
	isAnimating: boolean;
	onAnimationComplete: () => void;
	frustumSizeConstant?: number;
	onLoadingProgress?: (progress: number) => void;
	onModelLoaded?: () => void;
	recording?: boolean;
	resetCamera?: void;
};

export const MeshComponent = forwardRef<
	{ resetCamera: () => void },
	MeshComponentProps
>(
	(
		{
			fileType,
			isAnimating,
			onAnimationComplete,
			frustumSizeConstant = 1.025,
			onLoadingProgress,
			onModelLoaded,
			recording = false,
		},
		ref
	) => {
		const meshRef = useRef<Mesh>(null);
		const { camera, size } = useThree();
		const { setThreeElements, dimensions } = useCharacterPreviewContext();

		const { model, boundingBox, center, scale } = useModelLoader(
			fileType,
			onLoadingProgress
		);
		const { mixer, currentAction, playAnimation, stopAnimation } = useAnimation(
			model,
			fileType
		);

		const updateCamera = useCallback(() => {
			console.log("updateCamera");
			if (!meshRef.current) return;
			if (!recording)
				camera.position.set(
					center.x * scale,
					center.y * scale + meshRef.current.position.y,
					camera.position.z
				);
			if (camera instanceof OrthographicCamera) {
				const aspect = dimensions.width / dimensions.height;
				const maxDim = Math.max(
					boundingBox.max.x - boundingBox.min.x,
					boundingBox.max.y - boundingBox.min.y,
					boundingBox.max.z - boundingBox.min.z
				);
				const frustumSize = scale * maxDim * frustumSizeConstant;
				// center.multiplyScalar(scale);

				camera.left = (frustumSize * aspect) / -2;
				camera.right = (frustumSize * aspect) / 2;
				camera.top = frustumSize / 2;
				camera.bottom = frustumSize / -2;
			}
			if (!recording) {
				camera.updateProjectionMatrix();
				setThreeElements({
					camera,
					mesh: meshRef.current,
				});
			}
		}, [
			boundingBox.max.x,
			boundingBox.max.y,
			boundingBox.max.z,
			boundingBox.min.x,
			boundingBox.min.y,
			boundingBox.min.z,
			camera,
			center.x,
			center.y,
			dimensions.height,
			dimensions.width,
			frustumSizeConstant,
			recording,
			scale,
			setThreeElements,
		]);

		const resetCamera = useCallback(() => {
			console.log("resetCamera");
			if (!meshRef.current) return;
			if (!recording) {
				camera.position.setY(center.y * scale + meshRef.current.position.y);
				camera.updateProjectionMatrix();
				setThreeElements({
					camera,
					mesh: meshRef.current,
				});
			}
		}, [camera, center.y, recording, scale, setThreeElements]);

		useEffect(() => {
			if (model && meshRef.current) {
				meshRef.current.clear();
				meshRef.current.add(model);
				updateCamera();
				if (onModelLoaded) onModelLoaded();
			}
		}, [model, onModelLoaded, updateCamera]);

		// useEffect(() => {
		// 	if (camera instanceof OrthographicCamera) {
		// 		console.log("setThreeElements");
		// 		setThreeElements({
		// 			camera,
		// 			mesh: meshRef.current!,
		// 		});
		// 	}
		// }, [camera, setThreeElements]);

		useEffect(() => {
			if (isAnimating && currentAction) {
				playAnimation();
			} else if (!isAnimating && currentAction) {
				stopAnimation();
			}
		}, [isAnimating, currentAction, playAnimation, stopAnimation]);

		useFrame((state, delta) => {
			if (mixer && isAnimating) {
				mixer.update(delta);
				if (
					currentAction &&
					currentAction.time >= currentAction.getClip().duration
				) {
					currentAction.stop();
					onAnimationComplete();
				}
			}
		});
		useImperativeHandle(ref, () => ({
			resetCamera,
		}));
		return (
			<mesh ref={meshRef} scale={scale} castShadow>
				<meshStandardMaterial />
			</mesh>
		);
	}
);

MeshComponent.displayName = "MeshComponent";
