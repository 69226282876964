import { Button } from "@nextui-org/react";
import {
	IoArrowBackOutline,
	IoArrowForwardOutline,
	IoCloseOutline,
} from "react-icons/io5";
import { useRiggingContext } from "../../contexts/RiggingContext";
import { CharacterCreationView } from "../../viewer/CharacterCreationView";

import { ControlPosition } from "react-draggable";
import { Matrix4, Vector3 } from "three";
import { Keypoints } from "./KeypointMarkers";
import {
	useCharacterCreationContext,
	useCharacterPreviewContext,
} from "@/contexts";
import { CharacterCreationStep } from "@/hooks/useCharacterCreation";
import { EventName, getMixPanelClient } from "@/app/mixpanel";

export const CharacterRigging: React.FC = () => {
	const { rigCharacter, setStep } = useCharacterCreationContext();
	const { riggingKeyLocation } = useRiggingContext();
	const { dimensions, threeElements } = useCharacterPreviewContext();

	const screenToWorld = (x: number, y: number) => {
		const c = new Vector3(
			(x / dimensions.width) * 2 - 1,
			(-y / dimensions.height) * 2 + 1,
			-1
		);
		return c.unproject(threeElements.camera);
	};

	const mp = getMixPanelClient();

	const screenToCharacterLocal = (x: number, y: number) => {
		const matrixWorld = new Matrix4().copy(threeElements.mesh.matrixWorld);
		const b = matrixWorld.invert();
		const c = screenToWorld(x, y);
		const result = c.applyMatrix4(b);
		return result;
	};
	const computeRiggingData = () => {
		let resOutput: { [key: string]: ControlPosition } = {};
		for (const key in riggingKeyLocation) {
			const val = riggingKeyLocation[key];
			const rigLocationCharacterLocal = screenToCharacterLocal(val.x, val.y);
			resOutput[key] = rigLocationCharacterLocal;
		}
		return rigCharacter(resOutput);
	};

	return (
		<div className="relative text-center space-y-2 -mt-4">
			<h3 className="text-medium sm:text-xl font-semibold">
				Now let&apos;s prepare the animation of your character!
			</h3>
			<p className="text-sm sm:text-lg">Move the markers on your model</p>

			<CharacterCreationView>
				<Keypoints
					canvasHeight={dimensions.height}
					canvasWidth={dimensions.width}
				/>
			</CharacterCreationView>

			<div className="space-x-3 space-y-2 mt-2">
				<Button
					variant="light"
					startContent={<IoArrowBackOutline size={15} />}
					onPress={() => {
						setStep(CharacterCreationStep.previewMesh);
					}}
					size="lg"
					className="h-8 min-h-8 sm:h-12 sm:min-h-12 md:h-12 md:min-h-12 text-sm sm:text-medium rounded-[0.6rem] sm:rounded-2xl"
				>
					Character preview
				</Button>
				<Button
					startContent={<IoArrowForwardOutline size={15} />}
					size="lg"
					onPress={() => {
						mp.track(EventName.StartRigging, {});
						computeRiggingData();
					}}
					className="h-8 min-h-8 sm:h-12 sm:min-h-12 md:h-12 md:min-h-12 text-sm sm:text-medium rounded-[0.6rem] sm:rounded-2xl"
				>
					Next
				</Button>
			</div>
		</div>
	);
};
