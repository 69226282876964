"use client";

import { StudioCard } from "@/components";
import { IoFlashOutline } from "react-icons/io5";
import { useCharacter } from "@/contexts/CharacterContext";
import { AnimationCreation } from "./AnimationCreation";
import {
	AnimationCreationProvider,
} from "@/contexts/AnimationCreationContext";

export const AnimationPanel: React.FC = () => {
	const panelTitle = "Step 2: Animation";
	const helpMessage = "Upload a video or select an animation";
	const { character } = useCharacter();
	const displayBorder =
		character?.isReadyForAnimation && !character.animatedCharacter;



	return (
		<StudioCard
			title={panelTitle}
			helpMessage={helpMessage}
			isStepActive={character?.isReadyForAnimation ?? false}
			icon={<IoFlashOutline className="w-7 h-7 sm:w-9 sm:h-9" />}
			displayBorder={displayBorder}
		>
			<AnimationCreationProvider>
				<div className="max-w-[400px] mx-auto flex flex-col gap-4 p-2">
					<AnimationCreation />
				</div>
			</AnimationCreationProvider>
		</StudioCard>
	);
};
