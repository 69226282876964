import { useAnimationCreationContext } from "@/contexts";
import { MotionVideo } from "./MotionVideo";
import { Button } from "@nextui-org/react";
import { IoRefresh, IoRefreshOutline } from "react-icons/io5";
import { EventName, getMixPanelClient } from "@/app/mixpanel";

export const AnimationPreview: React.FC = () => {
	const { cancelCreation } = useAnimationCreationContext();
	const mp = getMixPanelClient();
	return (
		<div className="text-center space-y-4 -mt-2">
			<h3 className="text-xl font-semibold">Your motion video</h3>
			<MotionVideo />
			<div className="space-x-3">
				<Button
					variant="light"
					startContent={<IoRefresh size={20} />}
					onPress={() => {
						mp.track(EventName.CancelCreation, {
							step: "Animation preview",
						});
						cancelCreation();
					}}
					size="lg"
				>
					New animation
				</Button>
			</div>
		</div>
	);
};
