"use client";
import React, { useEffect } from "react";
import {
	useAnimationCreationContext,
	useCharacterCreationContext,
} from "@/contexts";
import { AnimationCreationStep } from "@/hooks/useAnimationCreation";
import { useCharacter } from "@/contexts/CharacterContext";
import { VideoImporter } from ".";
import { AnimationSelector } from ".";
import { VideoUploader } from "./steps/VideoUploader";
import { AnimationProcessing } from "./AnimationProcessing";
import { AnimationPreview } from "./steps/Preview";
import { Divider } from "@nextui-org/react";
import { CharacterCreationStep } from "../character";

export const AnimationCreation: React.FC = () => {
	const { step: characterStep } = useCharacterCreationContext();
	const { step, setStep } = useAnimationCreationContext();
	const { character } = useCharacter();

	useEffect(() => {
		if (characterStep === CharacterCreationStep.initial) {
			setStep(AnimationCreationStep.Initial);
		}
	}, [characterStep, setStep]);

	const renderContent = () => {
		switch (step) {
			case AnimationCreationStep.Initial:
				return (
					<>
						<VideoImporter disabled={!character?.isReadyForAnimation} />
						<Divider className="my-1" />
						<AnimationSelector />
					</>
				);
			case AnimationCreationStep.Upload:
				return <VideoUploader />;
			case AnimationCreationStep.Processing:
				return <AnimationProcessing />;
			case AnimationCreationStep.Preview:
				return <AnimationPreview />;
			default:
				return null;
		}
	};

	return (
		<div className=" flex flex-col items-center gap-4">{renderContent()}</div>
	);
};
