export const DashedGradientCircle = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	return (
		<div className="relative w-12 h-12">
			<svg
				viewBox="0 0 100 100"
				className="w-full h-full absolute top-0 left-0"
			>
				<defs>
					<linearGradient
						id="gradient"
						x1="0%"
						y1="0%"
						x2="100%"
						y2="0%"
					>
						<stop offset="0%" stopColor="#FF44CC" />
						<stop offset="50%" stopColor="#3BAEFF" />
						<stop offset="100%" stopColor="#FF44CC" />
					</linearGradient>
				</defs>
				<path
					d="M50,2 A48,48 0 1,1 49.9999,2"
					fill="none"
					stroke="url(#gradient)"
					strokeWidth="5"
					strokeDasharray="10 20"
					strokeLinecap="round"
					className="origin-center"
					style={{
						transformBox: "fill-box",
						transformOrigin: "center",
						transform: "rotate(92deg)",
					}}
				/>
			</svg>
			<div className="absolute inset-0 flex items-center justify-center">
				{children}
			</div>
		</div>
	);
};
