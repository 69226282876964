import { useAuth } from "@/components/Auth";
import { useCharacter } from "@/contexts";
import { makeToastError } from "@/utils";
import {
	Dropdown,
	DropdownTrigger,
	Button,
	DropdownMenu,
	DropdownItem,
	DropdownSection,
} from "@nextui-org/react";
import toast from "react-hot-toast";
import {
	IoLinkOutline,
	IoShareOutline,
	IoShareSocialOutline,
} from "react-icons/io5";

export const ShareButton = () => {
	const { user } = useAuth();
	const { character } = useCharacter();
	return (
		<Dropdown>
			<DropdownTrigger>
				<Button
					className="w-8 h-8 min-w-8 min-h-8 sm:w-10 sm:h-10 sm:min-w-10 sm:min-h-10 md:w-10 md:h-10 md:min-w-10 md:min-h-10"
					isIconOnly
				>
					<IoShareSocialOutline className="w-5 h-5 md:w-6 md:h-6 lg:w-6 lg:h-6" />
				</Button>
			</DropdownTrigger>
			<DropdownMenu aria-label="Share actions" disabledKeys={["title"]}>
				<DropdownItem key="title">
					<span className="font-medium text-lg text-white"> Share to </span>
				</DropdownItem>
				<DropdownItem
					key="copy_link"
					startContent={<IoLinkOutline />}
					onPress={() => {
						if (!user || !character) return;
						const baseUrl = window.location.origin;
						const exportUrl = `${baseUrl}/c/${character.id}`;
						navigator.clipboard.writeText(exportUrl);
						toast.success("Link copied to clipboard");
					}}
				>
					Copy link
				</DropdownItem>
				{/* <DropdownItem key="new">New file</DropdownItem>
				<DropdownItem key="copy">Copy link</DropdownItem>
				<DropdownItem key="edit">Edit file</DropdownItem>
				<DropdownItem key="delete" className="text-danger" color="danger">
					Delete file
				</DropdownItem> */}
			</DropdownMenu>
		</Dropdown>
	);
};
