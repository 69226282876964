"use client";

import { useCharacterPreviewContext } from "@/contexts";
import { Keypoint } from "../steps/rigging/keypoint";
import { createContext, useContext, useEffect, useState } from "react";

interface IRiggingContext {
	riggingKeyLocation: Keypoint;
	setRiggingKeyLocation: React.Dispatch<React.SetStateAction<Keypoint>>;
}

const RiggingContext = createContext<IRiggingContext>({
	riggingKeyLocation: {
		chin: { x: 345, y: 100 },
		larm: { x: 195, y: 300 },
		rarm: { x: 495, y: 300 },
		lelbow: { x: 245, y: 250 },
		relbow: { x: 445, y: 250 },
		lknee: { x: 295, y: 450 },
		rknee: { x: 395, y: 450 },
		groin: { x: 80, y: 440 },
	} as Keypoint,
	setRiggingKeyLocation: () => {},
});

export const RiggingProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [riggingKeyLocation, setRiggingKeyLocation] = useState<Keypoint>({
		chin: { x: 100, y: 245 },
		larm: { x: 100, y: 290 },
		rarm: { x: 140, y: 290 },
		lelbow: { x: 100, y: 340 },
		relbow: { x: 140, y: 340 },
		lknee: { x: 100, y: 390 },
		rknee: { x: 140, y: 390 },
		groin: { x: 100, y: 440 },
	});

	return (
		<RiggingContext.Provider
			value={{
				riggingKeyLocation,
				setRiggingKeyLocation,
			}}
		>
			{children}
		</RiggingContext.Provider>
	);
};

export const useRiggingContext = () => useContext(RiggingContext);
